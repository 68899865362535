<div class="header">
  <div class="header-label">Дата вылета</div>
  <div class="header-dates-value">{{ selectedRangeLabel }}</div>
</div>

<div class="calendar-container" (clickOutside)="clickOutsideCalendar()">
  <div class="month-selector">
    <div class="select-prev-month" (click)="selectPrevMonth()">
      <img src="assets/icons/favorite-hotels/search-form-chevron-prev.svg">
    </div>
    <div class="month-name">{{ calendarCreatedDate | date:'LLLL YYYY' }}</div>
    <div class="select-next-month" (click)="selectNextMonth()">
      <img src="assets/icons/favorite-hotels/search-form-chevron-prev.svg">
    </div>
  </div>

  <table class="calendar-month-table">
    <tr>
      <th>Пн</th>
      <th>Вт</th>
      <th>Ср</th>
      <th>Чт</th>
      <th>Пт</th>
      <th>Сб</th>
      <th>Вс</th>
    </tr>

    @for (row of tableRows; track row) {
      <tr>
        @for (cell of row; track cell) {
          <td (click)="selectDate(cell)">
            <div class="day"
                 [class.has-tours]="prices()[cell.dateYMD]"
                 [class.disabled]="cell.disabled"
                 [class.other-month]="cell.otherMonth"
                 [class.range-start]="startDragDate && startDragDate.getTime() === cell.date.getTime()"
                 [class.range-stop]="stopDragDate && stopDragDate.getTime() === cell.date.getTime()"
                 [class.range]="(startDate && stopDate && startDate.getTime() < cell.date.getTime() && cell.date.getTime() < stopDate.getTime())"
                 [class.active]="
              (startDate && cell.date.getTime() === startDate.getTime()) ||
              (stopDate && cell.date.getTime() === stopDate.getTime())">
              <div [class.day-value]="!cell.disabled">
                <div class="day-number"
                     [attr.level]="prices()[cell.dateYMD] ? prices()[cell.dateYMD].hotLevel : 0">
                  {{ cell.dayNumber }}
                </div>
                <div class="price-value">
                  @if (prices()[cell.dateYMD]) {
                    от {{ prices()[cell.dateYMD].priceForTour / 1000 | number:'1.0-0' }} т.
                  } @else {
                    &nbsp;
                  }
                </div>
              </div>
            </div>
          </td>
        }
      </tr>
    }
  </table>
</div>
