import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WhatsappGetInstanceResponse } from '@api-clients/crm-api-client';
import { ContactsService } from '../../modules/whatsapp/services/contacts.service';
import {
  LoadedInstance,
  LoadedInstanceError,
  WhatsappContactsActionTypes,
} from './whatsapp-contacts.actions';

@Injectable()
export class WhatsappContactsEffects {
  
  loadInstance$ = createEffect(() => this.actions$.pipe(
    ofType(WhatsappContactsActionTypes.LoadInstance),
    switchMap(() =>
      this.contactsService.getInstance().pipe(
        map(
          (data: WhatsappGetInstanceResponse) => new LoadedInstance(data),
          catchError(err => of(new LoadedInstanceError(err))),
        ),
      ),
    ),
  ));
  constructor(private actions$: Actions, private contactsService: ContactsService) {}
}
