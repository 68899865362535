import { AsyncPipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { WorkerCurrentCommissionResult } from '@api-clients/api-client/dist/models';
import { delayWhen, retryWhen, switchMapTo } from 'rxjs/operators';
import { CommissionService } from './services/commission.service';

const TIMER_DUE_TIME = 5 * 60 * 1000;

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, DecimalPipe],
})
export class CommissionComponent implements OnInit {
  public commission$: Observable<WorkerCurrentCommissionResult>;
  constructor(private commissionService: CommissionService) {
    this.commission$ = timer(0, TIMER_DUE_TIME).pipe(
      retryWhen(errors => errors.pipe(delayWhen(() => timer(60 * 1000)))),
      switchMapTo(this.commissionService.fetchCurrentCommission()),
    );
  }

  ngOnInit(): void {}
}
