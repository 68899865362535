import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-worker-name',
  templateUrl: './worker-name.component.html',
  styleUrls: ['./worker-name.component.scss'],
  standalone: true,
  imports: [RouterLink],
})
export class WorkerNameComponent {
  @Input() crmCardId: number;
  @Input() name: string;
  @Input() isFirst: boolean;
  @Output() nameClicked: EventEmitter<void> = new EventEmitter<void>();

  nameClick(): void {
    this.nameClicked.emit();
  }
}
