<div class="countries" (click)="openDropdown()">
  <div #countries class="label">
    @if (activeCountry()) {
      в <span class="country-label">{{ activeCountry().name }}</span>
      @if (selectedRegionsLabel()) {
        <span>({{ selectedRegionsLabel() }})</span>
      }
    } @else {
      в <span class="country-label">-выберите страну-</span>
    }
  </div>
</div>
