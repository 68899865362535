import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AiGptCompleteRequest, AiGptCompleteResponse } from '@api-clients/api-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';
import { AmplitudeTrackService } from '../../../core/services/amplitude/amplitude-track.service';
import { GPT_COMPLETE_REQUEST } from '../../../core/services/amplitude/amplitudeEvents';

@Injectable({
  providedIn: 'root',
})
export class AiGptApiService {
  constructor(private http: HttpClient, private amplitudeTrackService: AmplitudeTrackService) {}

  complete(request: AiGptCompleteRequest, prompt: string): Observable<AiGptCompleteResponse> {
    this.amplitudeTrackService.trackEvent(GPT_COMPLETE_REQUEST, {
      Prompt: prompt,
      Place: request.place,
    });
    return this.http
      .post<ApiResponse<AiGptCompleteResponse>>(`${AppConfig.commonApiUrl}/ai-gpt/complete`, request)
      .pipe(
        apiResponsePipe,
        map(response => response as AiGptCompleteResponse),
      );
  }
}
