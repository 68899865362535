import { NgClass } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { PreloaderComponent } from '../../../../ui-components/preloader/preloader.component';
import { SearchService } from './services/search.service';
import { Notify, NotifyService, NotifyTypeEnum } from '../../../../shared/notify/services/notify.service';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { MAIN_SEARCH_USE } from '../../../../core/services/amplitude/amplitudeEvents';
import { SearchHistoryService } from './services/search-history.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';

@UntilDestroy()
@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClickOutsideDirective, TranslateModule, NgClass, PreloaderComponent],
})
export class SearchFormComponent implements OnInit {
  public isLoading: boolean;
  public isShowHelper = false;
  public searchHistory: string[] = [];
  public readonly screenType: ScreenTypes = ScreenTypes.TOP_MENU;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private notifyService: NotifyService,
    private amplitudeTrackService: AmplitudeTrackService,
    private searchHistoryService: SearchHistoryService,
  ) {
    this.searchHistory = this.searchHistoryService.getSearchHistory();
  }

  ngOnInit(): void {
    const term = this.termQueryValue;
    if (term) {
      this.searchBySlug(term);
    }

    this.searchService.clearSearchInputValue$
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.clearInputValue());
  }

  searchBySlug(term?: string): void {
    this.isLoading = true;
    this.hideHelper();

    const searchInputValue = term || this.searchInput.nativeElement.value;
    this.amplitudeTrackService.trackEvent(MAIN_SEARCH_USE, {
      term: searchInputValue,
      screenType: this.screenType,
    });
    if (!searchInputValue.length) {
      this.isLoading = false;
      const notify: Notify = {
        title: 'Поиск клиента',
        text: 'Строка поиска пустая, пожалуйста, напишите туда что-нибудь =)',
        type: NotifyTypeEnum.error,
      };
      this.notifyService.create(notify);
      return;
    }
    this.searchService
      .searchBySlug(searchInputValue.trim())
      .pipe(untilDestroyed(this))
      .subscribe(results => {
        this.searchHistoryService.saveSearchQuery(searchInputValue);
        this.searchService.searchResults$.next({ data: results?.list, term: searchInputValue });
        this.router.navigate(['search-results']).then();
        this.isLoading = false;
        this.cdRef.markForCheck();
      });
  }

  set changeQuery(term) {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { term } }).then();
  }

  get termQueryValue(): string {
    return this.activatedRoute.snapshot.queryParamMap.get('term');
  }

  clearInputValue(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
      this.cdRef.detectChanges();
    }
  }

  showHelper() {
    this.searchHistory = this.searchHistoryService.getSearchHistory();
    this.isShowHelper = true;
  }

  hideHelper() {
    this.isShowHelper = false;
  }
}
