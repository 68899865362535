import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkerCurrentCommissionResult } from '@api-clients/api-client/dist/models';
import { delayWhen, retryWhen } from 'rxjs/operators';
import { timer } from 'rxjs';
import { AppConfig } from '../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../api-response.pipe';
import { ApiResponse } from '../../../../../../../../backend/src/models/models';

@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  constructor(private http: HttpClient) {}

  fetchCurrentCommission() {
    return this.http
      .get<ApiResponse<WorkerCurrentCommissionResult>>(`${AppConfig.apiUrl}/worker/current-commission`)
      .pipe(
        retryWhen(errors => errors.pipe(delayWhen(() => timer(60 * 1000)))),
        apiResponsePipe,
      );
  }
}
