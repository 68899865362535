<div class="control">
  <div class="form-group">
    <input type="text" class="form-control" placeholder="Поиск по местности"
           [formControl]="placeNameControl"
           [matAutocomplete]="auto"
           (keydown.enter)="findPlace()" />
  </div>
  @if (placeNameControl.value.length) {
    <button type="button" class="btn-remove-address" (click)="clearInput()">
      <img class="icon" src="assets/icons/favorite-hotels/close-black.svg">
    </button>
  }
</div>

<mat-autocomplete #auto="matAutocomplete" (closed)="onAutocompleteClosed()">
  @for (variant of placesVariants(); track variant) {
    <mat-option [value]="variant" (click)="findPlace(variant)">
      {{ variant }}
    </mat-option>
  }
</mat-autocomplete>
