<div
  #mainWrapper
  class="item-wrapper"
  [ngClass]="{
    'is-multiple-mode': isMultipleMode,
    'is-disabled': !isEnabled,
    'is-placeholder': isPlaceholder,
    'is-item-component-wrapper-hidden': !isItemComponentWrapperVisible(),
  }"
  (click)="mainWrapperClick()"
>
  @if (isMultipleMode) {
    <div class="title">
      {{ titleLabel | translate }}
      @if (recordsCount) {
        ({{ recordsCount }})
      }

      <div class="item-controls">
        @if (isPlaceholder) {
          <div class="icon-add" (click)="showPlaceholderModal()">
            <img src="assets/icons/plus-bold-white.svg" />
          </div>
        }
        @if (isItemComponentWrapperVisible()) {
          <app-slide-toggle [defaultValue]="isEnabled" size="big" (change)="changeIsEnabled($event)" />
        }
      </div>
    </div>
  }
  <div class="item-component-wrapper" [class.is-visible]="isItemComponentWrapperVisible()">
    <div #itemComponentWrapper></div>
  </div>
</div>
