import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchFormParamsDepartCity } from '@api-clients/api-client';

@Component({
  selector: 'app-search-form-depart-cities-dropdown',
  templateUrl: './search-form-depart-cities-dropdown.component.html',
  styleUrls: ['./search-form-depart-cities-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchFormDepartCitiesDropdownComponent {
  @Input() activeDepartCityId: number;
  @Input() departCities: SearchFormParamsDepartCity[] = [];

  @Output() selected = new EventEmitter<SearchFormParamsDepartCity>();

  selectDepartCity(departCity: SearchFormParamsDepartCity) {
    this.selected.emit(departCity);
  }
}
