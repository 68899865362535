import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { delay, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';
import { SelectedZenmodeCard } from '../../../../../../+state/deals/deals.interface';
import { DealStatus } from '../../../../../../models/deal-status';

@Component({
  selector: 'app-zenmode-paginate',
  templateUrl: './zenmode-paginate.component.html',
  styleUrls: ['./zenmode-paginate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class ZenmodePaginateComponent implements OnInit, OnDestroy {
  private readonly debounceTime = 500;
  private readonly debounceTimeAfterLoading = 300;
  private destroy$ = new Subject<void>();
  isLoadingLeft = false;
  isLoadingRight = false;
  constructor(
    private dealsFacade: DealsFacade,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  get prevText(): Observable<string> {
    return this.translate.stream('PAGES.ZENMODE.PAGINATE.PREVIOUS');
  }

  get nextText(): Observable<string> {
    return this.dealsFacade.selectedZenmodeCard$.pipe(
      switchMap((selectedCard: SelectedZenmodeCard) =>
        selectedCard && (selectedCard.isTouched || selectedCard.status === DealStatus.postSale)
          ? this.translate.stream('PAGES.ZENMODE.PAGINATE.NEXT')
          : this.translate.stream('PAGES.ZENMODE.PAGINATE.SKIP'),
      ),
    );
  }

  private toggleLoadingWithDelay(action: () => void, isLeft: boolean) {
    of(null)
      .pipe(
        tap(() => (isLeft ? (this.isLoadingLeft = true) : (this.isLoadingRight = true))),
        delay(this.debounceTime),
        tap(action), // Используйте tap, если action не возвращает Observable
        delay(this.debounceTimeAfterLoading),
        finalize(() => {
          this.isLoadingLeft = false;
          this.isLoadingRight = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  previousPage() {
    this.toggleLoadingWithDelay(() => this.dealsFacade.selectPreviousCard(false), true);
  }

  nextPage() {
    this.toggleLoadingWithDelay(() => {
      combineLatest([this.dealsFacade.selectedZenmodeCard$, this.dealsFacade.zenmodeList$])
        .pipe(
          take(1),
          map(([selectedCard, zenmodeList]) => {
            let isSkipped = !selectedCard?.isTouched;
            if (selectedCard?.status === DealStatus.postSale) {
              isSkipped = false;
            }
            const currentZenmodeListItem = zenmodeList.find(item => item?.card?.id === selectedCard?.cardId);
            const nextTask = currentZenmodeListItem?.nextTask;

            return {
              isSkipped,
              nextTask,
              dealId: selectedCard?.dealId,
              selectedCard,
            };
          }),
        )
        .subscribe(({ isSkipped, nextTask, selectedCard }) => {
          this.dealsFacade.selectNextCard(isSkipped, nextTask, selectedCard);
        });
    }, false);
  }

  get isDisabledNextButton$(): Observable<boolean> {
    return combineLatest([
      this.dealsFacade.zenmodeList$,
      this.dealsFacade.viewedCards$,
      this.dealsFacade.backtrackedCards$,
      of(this.isLoadingRight || this.isLoadingLeft),
    ]).pipe(
      map(([zenmodeList, viewedCards, backtrackedCards, isLoading]) => {
        if (isLoading || !zenmodeList || zenmodeList.length === 0) {
          return true;
        }

        // Если есть карточки в backtrackedCards, кнопка "Вперед" должна быть активна
        if (backtrackedCards.length > 0) {
          return false;
        }

        const viewedDealIds = new Set(viewedCards.map(card => card.dealId));
        return zenmodeList.every(item => viewedDealIds.has(item.deal.id));
      }),
    );
  }

  get isDisabledBackButton$(): Observable<boolean> {
    return combineLatest([
      this.dealsFacade.zenmodeHistory$,
      this.dealsFacade.selectedZenmodeCard$,
      of(this.isLoadingRight || this.isLoadingLeft),
    ]).pipe(
      map(([zenmodeHistory, selectedZenmodeCard, isLoading]) => {
        const isOnlyCardInZenmodeHistory =
          zenmodeHistory.length === 1 && zenmodeHistory[0].cardId === selectedZenmodeCard.cardId;

        return isLoading || isOnlyCardInZenmodeHistory || zenmodeHistory.length === 0;
      }),
    );
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
