<div class="regions-dropdown"
     [class.size-lg]="regionsCount > regionsLgSizeCount">
  <div class="select-all-container" (click)="selectAllRegions()">
    <span class="select-all">Выбрать все</span>
  </div>
  <div class="dropdown-items" [class.two-column]="regionsCount > regionsLgSizeCount"
       (clickOutside)="outsideClick()"
       [delayClickOutsideInit]="true">
    @for (item of items; track item.id) {
      <div class="dropdown-item">
        <div class="checkbox">
          <label>
            <input type="checkbox"
                   [(ngModel)]="item.selected"
                   (change)="selectRegion()">
            {{ item.name }}
          </label>
        </div>
      </div>
    }
  </div>
</div>
