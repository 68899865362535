<div class="presentation">
  <div class="label">{{ presentation.name }}</div>
  <div class="employee-name">{{ presentation.employeeName }}</div>
  <app-audio
    [screenType]="screenType"
    [mediaSource]="presentation.fileUrl"
    [type]="audioPresentationType"
    [itemId]="presentation.id"
    (transcriptionResult)="transcriptionResult.set($event)"
    />
  @if (transcriptionResult()) {
    <div class="send-as-text-toggle-wrapper">
      <app-slide-toggle
        [defaultValue]="false"
        labelText="Отправить как текст"
        (change)="setSendAsText($event)"
      />
    </div>
  }
  <app-slide-toggle
    class="select-presentation-radio"
    [defaultValue]="presentation.id === activePresentation.id"
    [readonly]="presentation.id === activePresentation.id"
    (change)="setActive()"
    />
  @if (presentation.isStandard) {
    <span class="status-icon is-standard">
      {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.IS_STANDARD' | translate }}
    </span>
  }
  @if (presentation.isWithChildren) {
    <span class="status-icon is-with-children">
      {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.IS_WITH_CHILDREN' | translate }}
    </span>
  }
  @if (presentation.isMaleVoice) {
    <span class="status-icon is-male-voice">
      {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.IS_MALE_VOICE' | translate }}
    </span>
  }
  @if (presentation.isLanguageKz) {
    <span class="status-icon is-language-kz">
      {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.IS_LANGUAGE_KZ' | translate }}
    </span>
  }
</div>
