import { Directive, ElementRef } from '@angular/core';

/**
 * Директива для управления текстовым полем ввода
 */
@Directive({
  selector: '[appTextInputControl]',
  exportAs: 'appTextInputControl',
  standalone: true,
})
export class TextInputControlDirective {
  constructor(private el: ElementRef) {}

  /**
   * Вставляет текст в позицию каретки
   */
  insertText(text: string, needTrim = false): void {
    const textarea: HTMLTextAreaElement = this.el.nativeElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Вставляем текст на позицию каретки
    let value = textarea.value.substring(0, start) + text + textarea.value.substring(end);
    if (needTrim) {
      value = value.trim();
    }
    textarea.value = value;

    // Устанавливаем каретку после вставленного текста
    textarea.selectionStart = textarea.selectionEnd = start + text.length;
    textarea.focus();
    // Триггер события изменения текста
    textarea.dispatchEvent(new Event('input'));
  }
}
