import { Component, Input } from '@angular/core';
import { TourPackageClaimNotification } from '@api-clients/crm-api-client/dist/models';
import { TranslateModule } from '@ngx-translate/core';
import { ExternalLinkDirective } from '../../../../../../shared/directives/external-link.directive';

@Component({
  selector: 'app-tour-package-docs-ready',
  templateUrl: './tour-package-docs-ready.component.html',
  styleUrls: ['./tour-package-docs-ready.component.scss'],
  standalone: true,
  imports: [TranslateModule, ExternalLinkDirective],
})
export class TourPackageDocsReadyComponent {
  @Input() notification: TourPackageClaimNotification;
}
