import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from '../../environments/environment';
import { AuthenticationService } from '../core/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (req.url.startsWith(AppConfig.andromedaApiUrl)) {
          return throwError(err);
        }

        if (err.status === 401 || err.status === 403) {
          this.authenticationService.logout();
          location.reload();
        }

        const error = err?.error || err.error.message || err.statusText;
        return throwError(error);
      }),
    );
  }
}
