<div class="main-search-form"
  (clickOutside)="hideHelper()"
  (focusin)="showHelper()">
  <label>
    <input
      #searchInput
      (keydown.enter)="searchBySlug()"
      class="search-input"
      type="text"
      placeholder="{{ 'LAYOUT.TOP.FORM.PLACEHOLDER' | translate }}"
      />
    <button (click)="searchBySlug()" class="search-button"></button>
  </label>
  <div class="helper-popup rounded-border" [ngClass]="{'show':isShowHelper}">

    @if (searchHistory?.length) {
      <div class="title">Последнее что вы искали:</div>
      <ul class="helper-popup-list recent-search">
        @for (item of searchHistory; track item; let i = $index) {
          <li class="helper-popup-item recent-search"
            (click)="searchBySlug(item)"
            >
            <img src="./assets/icons/search-popup/clock.svg" class="icon"  alt="" />
            <span class="text">{{item}}</span>
          </li>
        }
      </ul>
    }


    <div class="title">Можно искать:</div>
    <ul class="helper-popup-list">
      <li class="helper-popup-item">
        <img src="./assets/icons/search-popup/name.svg" alt="" />
        <span class="name">По имени:</span>
        <span class="text">Александр, Серик и т.д.</span>
      </li>
      <li class="helper-popup-item">
        <img src="./assets/icons/search-popup/code.svg" alt="" />
        <span class="name">По номеру:</span>
        <span class="text">3384949, 77753839223</span>
      </li>
      <li class="helper-popup-item">
        <img src="./assets/icons/search-popup/number.svg" alt="" />
        <span class="name">Обратку по коду:</span>
        <span class="text">R259393</span>
      </li>
      <li class="helper-popup-item">
        <img src="./assets/icons/search-popup/tour-id.svg" alt="" />
        <span class="name">Тур по номеру:</span>
        <span class="text">RS259393, #1021940515</span>
      </li>
    </ul>
    <div class="info-text">
      Если номер тура состоит только из цифр, то вписывать его надо с # в начале
    </div>
  </div>
</div>

@if (isLoading) {
  <app-preloader></app-preloader>
}
