import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecommendationsCounterService {
  private recommendationsNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  recommendationsNumber$: Observable<number> = this.recommendationsNumber.asObservable();

  set recommendationsTotalNumber(total: number) {
    this.recommendationsNumber.next(total);
  }
}
