<nav class="aside-nav">
  @if (shouldDisplayChats$ | async) {
    <div class="section-switcher">
      <div class="section-toggler" (click)="toggleRoute()">
        <div class="toggler-circle" [class.active]="isChatsRoute()"></div>
        <div class="toggler-content">
          <div class="toggler-state" [class.active]="!isChatsRoute()">
            <img
              src="assets/icons/aside-menu/deals-icon.svg"
              class="toggler-state-icon"
              [alt]="'LAYOUT.ASIDE.DEALS' | translate"
              [title]="'LAYOUT.ASIDE.DEALS' | translate"
              />
          </div>
          <div class="toggler-state" [class.active]="isChatsRoute()">
            <img
              src="assets/icons/aside-menu/chats-icon.svg"
              class="toggler-state-icon"
              [alt]="'LAYOUT.ASIDE.CHATS' | translate"
              [title]="'LAYOUT.ASIDE.CHATS' | translate"
              />
          </div>
        </div>
        <div class="toggler-subcircle" [class.active]="isChatsRoute()"></div>
      </div>

      @if (isChatsRoute()) {
        <div
          class="section-label"
          [class.clickable]="!currentStageChatListFilterIsAll()"
          [class.active]="currentStageChatListFilterIsAll()"
          (click)="resetStageFilter()"
          >
          @if (unreadChatsCount()) {
            <span class="section-counter">{{ unreadChatsCount() }}</span>
          }
          <img width="24" height="24" src="assets/icons/aside-menu/chats-icon.svg" alt="" srcset="" />
          <span>{{ 'LAYOUT.ASIDE.CHATS' | translate }} </span>
        </div>
      } @else {
        <div class="section-label active clickable" (click)="openDealsRoute()">
          @if (dealsTotalCount()) {
            <span class="section-counter">{{ dealsTotalCount() }}</span>
          }
          <img width="24" height="24" src="assets/icons/aside-menu/deals-icon.svg" alt="" srcset="" />
          <span>{{ 'LAYOUT.ASIDE.DEALS' | translate }} </span>
        </div>
      }
    </div>
  } @else {
    <a [routerLink]="['/deals']" routerLinkActive="active" class="aside-nav-clients">
      {{ 'LAYOUT.ASIDE.DEALS' | translate }}
      @if (dealsTotalCount()) {}
        <span class="counter">{{ dealsTotalCount() }}</span>
      </a>
    }
    @if (isChatsRoute()) {
      <div class="aside-chats-filters">
        <div class="aside-chats-filters__item">
          <a
            (click)="setStageFilter(chatListStageFilterKeys.MISSED_AND_URGENT)"
            class="chat-filter missed-and-urgent"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.MISSED_AND_URGENT"
          matTooltip="{{
            'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MISSED_AND_URGENT | translate
          }}"
            matTooltipClass="basic-tooltip"
            matTooltipPosition="right"
            >
            @if (getStageCounterBy$(chatListStageFilterKeys.MISSED_AND_URGENT) | async; as counter) {
              <span class="stage-counter">
                {{ counter }}
              </span>
            }
          </a>
          <a
            (click)="setStageFilter(chatListStageFilterKeys.NEW)"
            class="chat-filter new"
            [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.NEW"
            matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.NEW | translate }}"
            matTooltipClass="basic-tooltip"
            matTooltipPosition="right"
            >
            @if (getStageCounterBy$(chatListStageFilterKeys.NEW) | async; as counter) {
              <span class="stage-counter">
                {{ counter }}
              </span>
            }
          </a>
          <div class="stage-with-subfilters" [class.opened]="isDaysFiltersOpened()">
            <a
              (click)="setStageFilter(chatListStageFilterKeys.IN_PROGRESS)"
              class="chat-filter work-in-progress"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.IN_PROGRESS"
              matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.IN_PROGRESS | translate }}"
              matTooltipClass="basic-tooltip"
              matTooltipPosition="right"
              >
              @if (getStageCounterBy$(chatListStageFilterKeys.IN_PROGRESS) | async; as counter) {
                <span class="stage-counter">
                  {{ counter }}
                </span>
              }
            </a>
            @if (isDaysFiltersOpened()) {
              <div class="days-filters">
                <a
                  (click)="setStageFilter(chatListStageFilterKeys.FIRST_DAY)"
                  class="days-stage"
                  [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.FIRST_DAY"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.FIRST_DAY | translate
                }}"
                  matTooltipClass="basic-tooltip"
                  matTooltipPosition="right"
                  >
                  <span class="days-stage-label">1 д</span>
                  @if (getStageCounterBy$(chatListStageFilterKeys.FIRST_DAY) | async; as counter) {
                    <span class="stage-counter">
                      {{ counter }}
                    </span>
                  }
                </a>
                <a
                  (click)="setStageFilter(chatListStageFilterKeys.SECOND_DAY)"
                  class="days-stage"
                  [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.SECOND_DAY"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.SECOND_DAY | translate
                }}"
                  matTooltipClass="basic-tooltip"
                  matTooltipPosition="right"
                  >
                  <span class="days-stage-label">2 д</span>
                  @if (getStageCounterBy$(chatListStageFilterKeys.SECOND_DAY) | async; as counter) {
                    <span class="stage-counter">
                      {{ counter }}
                    </span>
                  }
                </a>
                <a
                  (click)="setStageFilter(chatListStageFilterKeys.THIRD_DAY)"
                  class="days-stage"
                  [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.THIRD_DAY"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.THIRD_DAY | translate
                }}"
                  matTooltipClass="basic-tooltip"
                  matTooltipPosition="right"
                  >
                  <span class="days-stage-label">3 д</span>
                  @if (getStageCounterBy$(chatListStageFilterKeys.THIRD_DAY) | async; as counter) {
                    <span class="stage-counter">
                      {{ counter }}
                    </span>
                  }
                </a>
                <a
                  (click)="setStageFilter(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS)"
                  class="days-stage"
                [class.active]="
                  currentStageChatListFilter() === chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS
                "
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS | translate
                }}"
                  matTooltipClass="basic-tooltip"
                  matTooltipPosition="right"
                  >
                  <span class="days-stage-label">4-7 д</span>
                  @if (getStageCounterBy$(chatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS) | async; as counter) {
                    <span class="stage-counter">
                      {{ counter }}
                    </span>
                  }
                </a>
                <a
                  (click)="setStageFilter(chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS)"
                  class="days-stage"
                  [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS"
                matTooltip="{{
                  'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS | translate
                }}"
                  matTooltipClass="basic-tooltip"
                  matTooltipPosition="right"
                  >
                  <span class="days-stage-label">> 7 д</span>
                  @if (getStageCounterBy$(chatListStageFilterKeys.MORE_THAN_SEVEN_DAYS) | async; as counter) {
                    <span class="stage-counter">
                      {{ counter }}
                    </span>
                  }
                </a>
              </div>
            }
          </div>
        </div>
        <div class="aside-chats-filters__item aside-chats-filters__item--bottom">
          <div class="aside-chats-filters__item">
            <a
              (click)="setStageFilter(chatListStageFilterKeys.MEETING)"
              class="chat-filter meeting"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.MEETING"
              matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.MEETING | translate }}"
              matTooltipClass="basic-tooltip"
              matTooltipPosition="right"
              >
              @if (getStageCounterBy$(chatListStageFilterKeys.MEETING) | async; as counter) {
                <span class="stage-counter">
                  {{ counter }}
                </span>
              }
            </a>
            <a
              (click)="setStageFilter(chatListStageFilterKeys.READY_FOR_BOOKING)"
              class="chat-filter ready-for-booking"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.READY_FOR_BOOKING"
            matTooltip="{{
              'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.READY_FOR_BOOKING | translate
            }}"
              matTooltipClass="basic-tooltip"
              matTooltipPosition="right"
              >
              @if (getStageCounterBy$(chatListStageFilterKeys.READY_FOR_BOOKING) | async; as counter) {
                <span class="stage-counter">
                  {{ counter }}
                </span>
              }
            </a>
            <a
              (click)="setStageFilter(chatListStageFilterKeys.POSTPONED)"
              class="chat-filter postponed"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.POSTPONED"
              matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.POSTPONED | translate }}"
              matTooltipClass="basic-tooltip"
              matTooltipPosition="right"
              >
              @if (getStageCounterBy$(chatListStageFilterKeys.POSTPONED) | async; as counter) {
                <span class="stage-counter">
                  {{ counter }}
                </span>
              }
            </a>
          </div>
          <div class="aside-chats-filters__item">
            <a
              (click)="setStageFilter(chatListStageFilterKeys.COLD_TOUCH)"
              class="chat-filter cold-touch"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.COLD_TOUCH"
              matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.COLD_TOUCH | translate }}"
              matTooltipClass="basic-tooltip"
              matTooltipPosition="right"
              >
              @if (getStageCounterBy$(chatListStageFilterKeys.COLD_TOUCH) | async; as counter) {
                <span class="stage-counter">
                  {{ counter }}
                </span>
              }
            </a>
            <a
              (click)="setStageFilter(chatListStageFilterKeys.POST_SALE)"
              class="chat-filter post-sale"
              [class.active]="currentStageChatListFilter() === chatListStageFilterKeys.POST_SALE"
              matTooltip="{{ 'LAYOUT.ASIDE.CHATS_FILTERS.' + chatListStageFilterKeys.POST_SALE | translate }}"
              matTooltipClass="basic-tooltip"
              matTooltipPosition="right"
              >
            </a>
          </div>
        </div>
      </div>
    } @else {
      <!-- TODO: Delete if no need in future-->
      @if ((isOnlyZenmode$ | async) === false) {
        <app-aside-chats />
      }
    }
  </nav>
