<div class="inner-content">
  <p>
    {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_CLAIM_OUTDATED_CONTENT.BOOKING' | translate }}
    <b>{{notification.operatorRequestNumber}}</b>
    {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_CLAIM_OUTDATED_CONTENT.IN_THE_NAME_OF' | translate }}
  </p>
  <a libExternalLink [externalURL]="notification.viewLink">
    {{notification.payerName}}
  </a>
  <p>{{'COMMON.PREPOSITIONS.OFF' | translate }}
    <b>{{notification.tourPackageCreatedAt | date: dateFormat }}</b>
    {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_CLAIM_OUTDATED_CONTENT.NOT_YET_PROCESSED' | translate }}</p>
</div>
