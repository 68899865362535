<div class="info">
  <div class="item">
    <img src="./assets/icons/notifications-center/client.svg"
      alt=""
      class="icon">
    <span class="text">
      <a libExternalLink [externalURL]="notification.viewLink">
        {{notification.payerName}}
      </a>
      &nbsp; {{'COMMON.PREPOSITIONS.OFF' | translate }} {{notification.tourPackageCreatedAt | date: dateFormat}}
    </span>
  </div>

  @if (notification.operatorRequestNumber; as operatorRequestNumber) {
    <div class="item">
      <img src="./assets/icons/notifications-center/request-number.svg"
        alt=""
        class="icon">
      <span class="text">
        {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_CLAIM_CONFIRMED_CONTENT.OPERATOR_REQUEST_NUMBER' | translate }}
        : {{operatorRequestNumber}}
      </span>
    </div>
  }
  @if (notification.operatorName; as operatorName) {
    <div class="item">
      <img src="./assets/icons/notifications-center/plane.svg"
        alt=""
        class="icon">
      <span class="text">
        {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_CLAIM_CONFIRMED_CONTENT.OPERATOR_NAME' | translate }}
        : {{operatorName}}
      </span>
    </div>
  }
  @if (notification.countryName) {
    <div class="item">
      <img src="./assets/icons/notifications-center/region.svg"
        alt=""
        class="icon">
      <span class="text">
        @if (notification.countryName; as countryName) {
          <p>{{countryName}},</p>
        }
        @if (notification.regionName; as regionName) {
          <p>{{regionName}},</p>
        }
        @if (notification.hotelName; as hotelName) {
          <p>{{hotelName}}</p>
        }
      </span>
    </div>
  }
</div>
