<div class="status-selector"
     [matMenuTriggerFor]="statusMenu"
     (click)="toggleDropdown()">
  <app-status-indicator [status]="(currentManagerSipState$ | async)?.state"></app-status-indicator>
  <img src="./assets/icons/top-menu/{{ isOpen ? 'arrow-up' : 'arrow-down' }}.svg" alt="arrow icon">
</div>

<mat-menu #statusMenu="matMenu" xPosition="before">
  <button mat-menu-item (click)="onManagerStatusChange(true)">
    <app-status-indicator [status]="managerSipStateEnum.online"></app-status-indicator>
  </button>
  <button mat-menu-item (click)="onManagerStatusChange(false)">
    <app-status-indicator [status]="managerSipStateEnum.manual_busy"></app-status-indicator>
  </button>
</mat-menu>
