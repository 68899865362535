import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PopupService } from '../../../../../shared/services/popup-service.service';
import { RightSideMenuComponent } from '../right-side-menu.component';

@Injectable({
  providedIn: 'root',
})
export class RightSideMenuPopupService {
  private popupOpenSource = new BehaviorSubject<boolean>(false);
  popupOpen$ = this.popupOpenSource.asObservable();
  popupServiceOpen$ = this.popupService.popupOpenSource;

  constructor(private popupService: PopupService) {
    this.popupOpenSource.next(false);
    this.popupServiceOpen$.subscribe(data => {
      if (!data.isOpen) {
        this.popupOpenSource.next(false);
      }
    });
  }

  showPopup(): void {
    if (!this.isPopupOpen()) {
      this.popupService.showPopup(RightSideMenuComponent, null, {
        position: { right: 10, top: 60 },
        hasBackdrop: false,
      });

      this.popupOpenSource.next(true);
    }
  }

  hidePopup(): void {
    if (this.isPopupOpen()) {
      this.popupService.closeAllModals();
      this.popupOpenSource.next(false);
    }
  }

  toggleMenu() {
    if (this.isPopupOpen()) {
      this.hidePopup();
    } else {
      this.showPopup();
    }
  }

  isPopupOpen(): boolean {
    return this.popupOpenSource.value;
  }
}
