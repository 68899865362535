import { Injectable } from '@angular/core';
import { ElectronService } from '../../../core/services/electron.service';
import { TaskItem, UpdateTasksListEvent } from '../../../models/ipcEvent';

@Injectable({
  providedIn: 'root',
})
export class DealTasksService {
  constructor(private electronService: ElectronService) {}

  public sendTasksToNotifications(tasks: TaskItem[]) {
    const eventData: UpdateTasksListEvent = {
      event: 'update-tasks-list',
      tasks,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
  }
}
