import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSince',
  standalone: true,
})
export class TimeSincePipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date(value);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return `${interval} г назад.`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return `${interval} м назад.`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return `${interval} дн назад.`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return `${interval} ч назад.`;
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return `${interval} м назад.`;
    }
    return `${Math.floor(seconds)} с назад.`;
  }
}
