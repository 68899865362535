import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NetworkStatusService } from '../../../../../shared/services/network-status.service';

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html',
  styleUrl: './network-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [NetworkStatusService],
})
export class NetworkStatusComponent {
  isOffline = toSignal<boolean>(this.networkStatusService.isOffline$);
  isSlowConnection = toSignal<boolean>(this.networkStatusService.isSlowConnection$);

  constructor(private networkStatusService: NetworkStatusService) {}
}
