<div class="inner-content">
  <p>
    {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_NEW_PAYMENT_CONTENT.CLIENT' | translate }}
  </p>
  <a libExternalLink [externalURL]="notification.viewLink">
    {{notification.payerName}}
  </a>
  <p>
    {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_NEW_PAYMENT_CONTENT.NEW_PAYMENT' | translate }}
  </p>
  <p>
    {{notification.amount | number : '1.0-0'}} {{'CURRENCY.' + notification.currency | translate}}.
  </p>
</div>
