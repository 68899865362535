import { DatePipe, DecimalPipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CallsFacade } from './app/+state/calls/calls.facade';
import { callsReducer } from './app/+state/calls/calls.reducers';
import { callInitialState } from './app/+state/calls/calls.state';
import { ChatMessagesEffects } from './app/+state/chat-messages/chat-messages.effects';
import { ChatMessagesFacade } from './app/+state/chat-messages/chat-messages.facade';
import { chatMessagesReducer } from './app/+state/chat-messages/chat-messages.reducers';
import { chatMessagesInitialState } from './app/+state/chat-messages/chat-messages.state';
import { ChatTimelineEffects } from './app/+state/chat-timeline/chat-timeline.effects';
import * as fromChatTimeline from './app/+state/chat-timeline/chat-timeline.reducer';
import { ChatEffects } from './app/+state/chats/chats.effects';
import { chatReducer, FEATURE_KEY } from './app/+state/chats/chats.reducer';
import { CurrentOutcomeCallFacade } from './app/+state/current-outcome-call/current-outcome-call.facade';
import { currentOutcomeCallReducer } from './app/+state/current-outcome-call/current-outcome-call.reducers';
import { currentOutcomeCallInitialState } from './app/+state/current-outcome-call/current-outcome-call.state';
import { DealsListFacade } from './app/+state/deals-list/deals-list.facade';
import { dealsListInitialState, dealsListReducer } from './app/+state/deals-list/deals-list.reducers';
import { DealsEffects } from './app/+state/deals/deals.effects';
import { DealsFacade } from './app/+state/deals/deals.facade';
import { dealsInitialState, dealsReducer } from './app/+state/deals/deals.reducers';
import { ManagerStateFacade } from './app/+state/manager-state/manager-state.facade';
import { managerStateReducer } from './app/+state/manager-state/manager-state.reducers';
import { managerSipInitialState } from './app/+state/manager-state/manager-state.state';
import { PopupFacade } from './app/+state/popup/popup.facade';
import { popupReducer } from './app/+state/popup/popup.reducers';
import { popupInitialState } from './app/+state/popup/popup.state';
import { RecommendationsEffects } from './app/+state/recommendations/recommendations.effects';
import { RecommendationsFacade } from './app/+state/recommendations/recommendations.facade';
import {
  recommendationsInitialState,
  recommendationsReducer,
} from './app/+state/recommendations/recommendations.redusers';
import { WhatsappContactsEffects } from './app/+state/whatsapp-contacts/whatsapp-contacts.effects';
import { WhatsappContactsFacade } from './app/+state/whatsapp-contacts/whatsapp-contacts.facade';
import { whatsappContactsReducer } from './app/+state/whatsapp-contacts/whatsapp-contacts.reducers';
import { whatsappContactsInitialState } from './app/+state/whatsapp-contacts/whatsapp-contacts.state';
import { workerConfigReducer } from './app/+state/worker-config/wokrer-config.reducers';
import { workerConfigInitialState } from './app/+state/worker-config/wokrer-config.state';
import { WorkerConfigEffects } from './app/+state/worker-config/worker-config.effects';
import { WorkerConfigFacade } from './app/+state/worker-config/worker-config.facade';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import {
  configServiceFactory,
  errorHandlerFactory,
  HttpLoaderFactory,
  maskConfigFunction,
  metaReducers,
} from './app/app.module';
import { CoreModule } from './app/core/core.module';
import { AppInitService } from './app/core/services';
import { ErrorInterceptor } from './app/interceptors/error.interceptor';
import { JwtInterceptor } from './app/interceptors/jwt.interceptor';
import { ChatsContainerTabService } from './app/modules/chats/container/components/chats-container-tab/chats-container-tabs-service';
import { SearchResultFiltersService } from './app/modules/deals/modules/deal-view/modules/search-tours/components/search-result/services/search-result-filters.service';
import { SearchResultUiService } from './app/modules/deals/modules/deal-view/modules/search-tours/components/search-result/services/search-result-ui.service';
import { SearchToursProgressService } from './app/modules/deals/modules/deal-view/modules/search-tours/services/search-tours-progress.service';
import { CrmCardUpdateService } from './app/modules/deals/modules/deal-view/services/crm-card-update.service';
import { HotOffersService } from './app/modules/hot-offers/hot-offers.service';
import { GoogleMapsLoaderService } from './app/modules/search/services/google-maps-loader.service';
import { SearchResultService } from './app/modules/search/services/search-result.service';
import { SearchTourFlightsService } from './app/modules/search/services/search-tour-flights.service';
import { SearchApiService } from './app/modules/search/services/search/search-api.service';
import { TourPackageCreateService } from './app/shared/services/tour-package-create.service';
import { AppConfig } from './environments/environment';

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  hideDelay: 100,
  touchendHideDelay: 1500,
  showDelay: 500,
};

if (AppConfig.production) {
  Sentry.init({
    dsn: 'https://51ad15dd7cf6e6f9e667a5505357a4ab@o4508012789694464.ingest.us.sentry.io/4508012792315904',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/gera-bff\.ht\.kz\/api/],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });

  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      CoreModule,
      AppRoutingModule,
      NgxPermissionsModule.forRoot(),
      NgxMaskModule.forRoot(maskConfigFunction),
      EffectsModule.forRoot([]),
      StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
      StoreRouterConnectingModule.forRoot(),
      StoreDevtoolsModule.instrument({ connectInZone: true }),
      StoreModule.forFeature('worker-config', workerConfigReducer, {
        initialState: workerConfigInitialState,
      }),
      StoreModule.forFeature('deals', dealsReducer, {
        initialState: dealsInitialState,
      }),
      StoreModule.forFeature('recommendations', recommendationsReducer, {
        initialState: recommendationsInitialState,
      }),
      StoreModule.forFeature('popup', popupReducer, {
        initialState: popupInitialState,
      }),
      StoreModule.forFeature('dealsList', dealsListReducer, {
        initialState: dealsListInitialState,
      }),
      StoreModule.forFeature('calls', callsReducer, {
        initialState: callInitialState,
      }),
      StoreModule.forFeature('managerSipState', managerStateReducer, {
        initialState: managerSipInitialState,
      }),
      EffectsModule.forFeature([DealsEffects]),
      EffectsModule.forFeature([RecommendationsEffects]),
      StoreModule.forFeature('current-outcome-call', currentOutcomeCallReducer, {
        initialState: currentOutcomeCallInitialState,
      }),
      EffectsModule.forFeature([WorkerConfigEffects]),
      StoreModule.forFeature('chat-messages', chatMessagesReducer, {
        initialState: chatMessagesInitialState,
      }),
      StoreModule.forFeature('whatsapp-contacts', whatsappContactsReducer, {
        initialState: whatsappContactsInitialState,
      }),
      EffectsModule.forFeature([WhatsappContactsEffects]),
      StoreModule.forFeature(FEATURE_KEY, chatReducer),
      EffectsModule.forFeature([ChatEffects]),
      StoreModule.forFeature(fromChatTimeline.chatTimelineFeatureKey, fromChatTimeline.reducer),
      EffectsModule.forFeature([ChatTimelineEffects]),
      EffectsModule.forFeature([ChatMessagesEffects]),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      ClipboardModule,
      MatTooltip,
    ),
    [DatePipe],
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions },
    // Глобальные сервисы приложения
    RecommendationsFacade,
    CallsFacade,
    PopupFacade,
    ManagerStateFacade,
    WorkerConfigFacade,
    SearchResultService,
    GoogleMapsLoaderService,
    SearchResultUiService,
    SearchResultFiltersService,
    SearchTourFlightsService,
    HotOffersService,
    TourPackageCreateService,
    SearchApiService,
    ChatsContainerTabService,
    SearchToursProgressService,
    ChatMessagesFacade,
    WhatsappContactsFacade,
    DealsFacade,
    DealsListFacade,
    CrmCardUpdateService,
    ChatMessagesFacade,
    CurrentOutcomeCallFacade,
    DecimalPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch(err => console.error(err));
