<div class="depart-cities-dropdown">
  <div class="dropdown-label-container">
    <div class="dropdown-label">Куда</div>
  </div>
  <div class="dropdown-items">
    @for (departCity of departCities; track departCity) {
      <button (click)="selectDepartCity(departCity)"
              [class.active]="departCity.id === activeDepartCityId"
              type="button"
              class="dropdown-item">
        @if (departCity.id === activeDepartCityId) {
          <img src="assets/icons/favorite-hotels/search-form-check.svg" class="icon-active" />
        }
        <div class="depart-city-name">{{ departCity.name }}</div>
      </button>
    }
  </div>
</div>
