<div class="inner-content">
  <p>
    {{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_DOCS_READY_CONTENT.IN_TOUR_PACKAGE' | translate }}
  </p>
  <a libExternalLink [externalURL]="notification.viewLink">
    N{{notification.tourPackageId}}
  </a>
  <p>{{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_DOCS_READY_CONTENT.IN_THE_NAME_OF' | translate }}</p>
  <a libExternalLink [externalURL]="notification.viewLink">
    {{notification.payerName}}
  </a>
  <p>{{'PAGES.NOTIFICATIONS_CENTER.TOUR_PACKAGE_DOCS_READY_CONTENT.READY' | translate }}</p>
</div>
