import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatsCounterService {
  private chatsNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  chatsNumber$: Observable<number> = this.chatsNumber.asObservable();

  set chatsTotalNumber(total: number) {
    this.chatsNumber.next(total);
  }
}
