import { WorkerGetConfigResult } from '@api-clients/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoadedConfig, LoadedConfigError, WorkerConfigActionTypes } from './wokrer-config.actions';
import { WorkerConfigService } from '../../modules/whatsapp/services/worker-config.service';

@Injectable()
export class WorkerConfigEffects {
  
  loadConfig$ = createEffect(() => this.actions$.pipe(
    ofType(WorkerConfigActionTypes.LoadConfig),
    switchMap(() =>
      this.workerConfigService.getWorkerConfig().pipe(
        map((data: WorkerGetConfigResult) => new LoadedConfig(data)),
        catchError(error => of(new LoadedConfigError({ error }))),
      ),
    ),
  ));

  constructor(private actions$: Actions, private workerConfigService: WorkerConfigService) {}
}
