<div class="message-info"

  >
  <div class="message-header">
    <div class="name">
      {{name}}
    </div>

    <div class="date">
      {{lastMessage.receivedAt | date: 'HH:mm'}}
    </div>
  </div>
  <div class="message">
    @if (lastMessage.isFromMe) {
      {{'COMMON.ME' | translate }}:
    }

    {{shortMessage}}
    <div class="counter-arrow">
      @if (hasUnreadMessages) {
        <div class="counter info"
          >
          1
        </div>
      }
      <a
        (click)="dealViewOpened()"
        [routerLink]="['/deals/view', crmId]"
        [queryParams]="{phone: phone.phone, code: phone.code}"
        class="arrow-right">
        <img src="assets/icons/deal-view/arrow-right.svg" alt="right">
      </a>
    </div>

  </div>

</div>
