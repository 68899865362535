import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchResultUiService {
  private toursVisibleSub = new BehaviorSubject<boolean>(true);
  private updateMapBoundsSub = new BehaviorSubject<boolean>(true);

  get toursVisibleUpdated$(): Observable<boolean> {
    return this.toursVisibleSub.asObservable();
  }

  get updateMapBounds$(): Observable<boolean> {
    return this.updateMapBoundsSub.asObservable();
  }

  updateToursVisible(visible: boolean): void {
    this.toursVisibleSub.next(visible);
  }

  updateMapBounds(): void {
    this.updateMapBoundsSub.next(true);
  }
}
