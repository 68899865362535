import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { WorkerGetConfigResult } from '@api-clients/api-client';
import { AppConfig } from '../../../../environments/environment';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class WorkerConfigService {
  constructor(private http: HttpClient) {}

  getWorkerConfig() {
    return this.http
      .get<ApiResponse<WorkerGetConfigResult>>(`${AppConfig.apiUrl}/worker/get-config`)
      .pipe(apiResponsePipe);
  }
}
