import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SearchResultService } from '../../../../../../../../search/services/search-result.service';
import { InitSearchRequest } from '../../../../../../../../search/services/search/websocket-tours.model';
import { SearchResultGroup } from '../../../search-tours.model';
import { SearchFormService } from '../../search-form/search-form.service';
import { SearchResultUiService } from '../services/search-result-ui.service';
import { SearchResultControlsComponent } from './controls/search-result-controls.component';
import { SearchResultMapComponent } from './map/search-result-map.component';
import { SearchResultPostFiltersComponent } from './post-filters/search-result-post-filters.component';
import { SearchResultToursComponent } from './tours/search-result-tours.component';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrl: './search-result.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SearchResultToursComponent,
    SearchResultToursComponent,
    SearchResultMapComponent,
    SearchResultControlsComponent,
    SearchResultPostFiltersComponent,
  ],
})
export class SearchResultComponent implements OnInit {
  resultGroupsSub = new BehaviorSubject<SearchResultGroup[]>([]);
  resultGroups$ = this.resultGroupsSub.asObservable();

  mapVisibleHotelIdsSub = new BehaviorSubject<number[]>([]);
  mapVisibleHotelIds$ = this.mapVisibleHotelIdsSub.asObservable();

  isShowTours = signal<boolean>(true);
  initSearchRequest = signal<InitSearchRequest>(undefined);

  minTourPrice = signal<number>(0);
  maxTourPrice = signal<number>(0);
  starsList = signal<string[]>([]);

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly uiService: SearchResultUiService,
    private readonly searchResultService: SearchResultService,
    private readonly searchFormService: SearchFormService,
  ) {}

  ngOnInit(): void {
    this.searchFormService.startSearch$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      if (this.initSearchRequest()) {
        const currentCountryId = this.initSearchRequest().params.params.countryId;
        const newCountryId = params.direction.countryId;

        if (currentCountryId !== newCountryId) {
          this.uiService.updateMapBounds();
        }
      } else {
        this.uiService.updateMapBounds();
      }
    });

    this.searchResultService
      .getSearchResultGroups$()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v.length),
      )
      .subscribe(resultGroups => {
        this.resultGroupsSub.next(resultGroups);
        this.updatePostFiltersValues();
      });

    this.searchFormService.currentInitSearchRequest$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(initSearchRequest => {
        this.initSearchRequest.set(initSearchRequest);
      });
  }

  toggleShowTours(): void {
    const newValue = !this.isShowTours();

    this.isShowTours.set(newValue);
    this.uiService.updateToursVisible(newValue);
  }

  mapBoundsHotelIds(hotelIds: number[]): void {
    this.mapVisibleHotelIdsSub.next(hotelIds);
  }

  private updatePostFiltersValues(): void {
    let minPrice = 0;
    let maxPrice = 0;
    const starsList = [];

    this.resultGroupsSub.value.forEach(group => {
      if (group.hotel.stars && !starsList.includes(group.hotel.stars)) {
        starsList.push(group.hotel.stars);
      }

      if (group.tours.length) {
        const price = group.tours[0].brandPrice.value;
        if (price > maxPrice) {
          maxPrice = price;
        }
        if (minPrice === 0 || price < minPrice) {
          minPrice = price;
        }
      }
    });

    this.starsList.set(starsList);
    this.maxTourPrice.set(maxPrice);
    this.minTourPrice.set(minPrice);
  }
}
