import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RightSideMenuItem } from '../../right-side-menu-item';

@Component({
  selector: 'app-right-side-menu-item',
  templateUrl: './right-side-menu-item.component.html',
  styleUrls: ['./right-side-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgClass, TranslateModule],
})
export class RightSideMenuItemComponent implements OnInit {
  @Input() item: RightSideMenuItem;
  @Input() isLast = false;

  @Output() close = new EventEmitter<void>();

  ngOnInit(): void {}
}
