import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  pure: true,
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchQuery: string): string {
    if (!searchQuery) return value;
    const regex = new RegExp(`(${searchQuery})`, 'gi');
    return value.replace(regex, `<mark>$1</mark>`);
  }
}
