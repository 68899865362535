<div class="dropdown-item"
     [class.active]="showCheckIcon()"
     (click)="showRegions()">
  @if (showCheckIcon()) {
    <img src="assets/icons/favorite-hotels/search-form-check.svg" class="check-icon" />
  }

  <div class="country-name">
    <img [attr.src]="'https://ht.kz/img/flag/' + country().code + '.png'" class="country-flag" />
    {{ country().name }}
  </div>

  @if (countryRegions().length > 1) {
    <div #regions class="show-regions"></div>
  }
</div>
