<div class="inner-content">
  <p>
    {{'PAGES.NOTIFICATIONS_CENTER.DEAL_ASSIGNED_CONTENT.CONTACT_WITH_CLIENT' | translate }}
  </p>
  <a (click)="openDealView(notification.crmCardId)">
    {{notification.clientName ? notification.clientName : notification.dealId}}
  </a>
  <p>{{'PAGES.NOTIFICATIONS_CENTER.DEAL_ASSIGNED_CONTENT.AS_SOON_AS_POSSIBLE' | translate }}
  </p>
</div>
