import { FeatureIntroConfig, FeatureIntroService, FeatureStep } from './feature-intro.service';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IntroManagementService implements OnDestroy {
  private queue: FeatureIntroConfig[] = [];
  private _introInProgress: boolean;
  private destroy$ = new Subject<void>();
  private elementSelectors = {
    VACATION_REMINDER: '#highlight',
    WORKER_LIST_CARD: '#highlightNewFeature',
    SALES_FUNNEL: '#salesFunnelIntro',
  };

  constructor(private featureIntroService: FeatureIntroService, private translate: TranslateService) {
    this.featureIntroService.introInProgress$
      .pipe(takeUntil(this.destroy$))
      .subscribe((inProgress) => {
        this._introInProgress = inProgress;
        if (!inProgress) {
          this.checkQueue();
        }
      });
  }

  public queueIntro(baseFeatureId: string, forced: boolean = false, additionalFeatureIds?: string[], callback?: () => void, canTry?: boolean) {
    this.getConfig(baseFeatureId, forced, additionalFeatureIds).subscribe(config => {
      const introConfig: FeatureIntroConfig = { ...config, isForced: forced, callback, canTry };
      if (!this._introInProgress) {
        this.showIntro(introConfig);
      } else if (forced){
        this.queue.unshift(introConfig);
      } else {
        this.queue.push(introConfig);
      }
    });
  }

  private getTranslationOrEmpty(key: string): Observable<string> {
    return this.translate.get(key).pipe(
      switchMap(value => value === key ? of('') : of(value))
    );
  }

  private getConfig(
    baseFeatureId: string,
    isForced: boolean = false,
    additionalFeatureIds?: string[],
  ): Observable<FeatureIntroConfig> {
    if (!additionalFeatureIds || additionalFeatureIds.length === 0) {
      const translatePath = `INTRO.NEW_FEATURES.${baseFeatureId}`;
      return combineLatest([
        this.translate.get(`${translatePath}.TITLE`),
        this.translate.get(`${translatePath}.P1`, { defaultValue: '' }),
        this.translate.get(`${translatePath}.P2`, { defaultValue: '' }),
        this.translate.get(`${translatePath}.CLOSE_TEXT`),
        this.translate.get(`${translatePath}.TRY_TEXT`),
        this.getTranslationOrEmpty(`${translatePath}.IMPORTANT_INFO`),
      ]).pipe(
        map(([title, p1, p2, closeText, tryText, importantInfo]: [string, string, string, string, string | null, string | null]) => {
          const buttonOptions = {
            closeText,
            tryText: tryText || '',
          };

          return {
            featureId: baseFeatureId,
            steps: [
              {
                elementSelector: this.elementSelectors[baseFeatureId] || '#defaultSelector',
                title,
                paragraphs: [p1, p2],
                importantInfo,
                position: 'right',
              },
            ],
            buttonOptions,
            isForced,
          };
        }),
      );
    } else {
      const translateKeys = additionalFeatureIds.reduce((keys, featureId) => {
        const translatePath = `INTRO.NEW_FEATURES.${baseFeatureId}.${featureId}`;
        keys.push(`${translatePath}.TITLE`, `${translatePath}.P1`, `${translatePath}.P2`, `${translatePath}.IMPORTANT_INFO`);
        return keys;
      }, []);

      const stepsConfig: FeatureStep[] = additionalFeatureIds.map(featureId => ({
        elementSelector: `#${featureId.toLowerCase()}-intro`,
        position: 'bottom',
        stepId: featureId,
      }));

      return this.translate.get(translateKeys).pipe(
        map(translations => {
          const checkTranslation = (key: string, value: string) => {
            return value === key ? '' : value;
          };
          stepsConfig.forEach(step => {
            const translatePath = `INTRO.NEW_FEATURES.${baseFeatureId}.${step.stepId}`;
            step.title = checkTranslation(`${translatePath}.TITLE`, translations[`${translatePath}.TITLE`]);
            step.importantInfo = checkTranslation(`${translatePath}.IMPORTANT_INFO`, translations[`${translatePath}.IMPORTANT_INFO`]);
            step.paragraphs = [
              checkTranslation(`${translatePath}.P1`, translations[`${translatePath}.P1`]),
              checkTranslation(`${translatePath}.P2`, translations[`${translatePath}.P2`])
            ].filter(paragraph => paragraph);
          });


          return {
            featureId: baseFeatureId,
            steps: stepsConfig,
            buttonOptions: {
              closeText: this.translate.instant('INTRO.CLOSE'),
              nextText: this.translate.instant('INTRO.NEXT'),
              prevText: this.translate.instant('INTRO.PREV'),
              stepNumbersOfText: this.translate.instant('INTRO.STEP_NUMBERS_OF'),
            },
            isForced,
          };
        })
      );
    }

  }

  private showIntro(introConfig: FeatureIntroConfig) {
    this.featureIntroService.introduceNewFeature(introConfig);
  }

  private checkQueue() {
    if (this.queue.length > 0) {
      const nextIntro = this.queue.find(intro => !intro.isForced) ?? this.queue[0];
      this.queue = this.queue.filter(intro => intro !== nextIntro);
      this.showIntro(nextIntro);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
