import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatSendMessageRequest } from '@api-clients/crm-api-client';
import { Observable, Subscription } from 'rxjs';
import {
  MODAL_MIN_HEIGHT,
  MODAL_VERTICAL_MARGIN,
  MODAL_WIDTH,
} from '../constants/content-creator-sizes.constants';
import { ContentCreatorComponent } from '../content-creator.component';
import { ChatTimeLineItem } from '../../chat-timeline/interfaces/chat-timeline.interface';
import { ContentCreatorContentType } from '../interfaces/content-creator.interface';
import { ContentCreatorModalControlService } from './content-creator-modal-control.service';

@Injectable({
  providedIn: 'root',
})
export class ContentCreatorModalService {
  private contentCreatorModalControlServiceSubscription: Subscription;

  constructor(
    public matDialog: MatDialog,
    private contentCreatorModalControlService: ContentCreatorModalControlService,
  ) {}

  public listen(): void {
    if (this.contentCreatorModalControlServiceSubscription) {
      return;
    }
    this.contentCreatorModalControlServiceSubscription =
      this.contentCreatorModalControlService.showModal$.subscribe(
        ({
          chatTimeLineItem,
          initialContentTypes,
          initialContentPlaceholderTypes,
          isOpenedFromPlaceholder,
        }) => {
          this.showModal(
            chatTimeLineItem,
            initialContentTypes,
            initialContentPlaceholderTypes,
            isOpenedFromPlaceholder,
          );
        },
      );
  }

  /**
   * Чтобы не было циклической зависимости,
   * вызываем открытие модального окна через ContentCreatorModalControlService
   */
  private showModal(
    chatTimeLineItem: ChatTimeLineItem | null,
    initialContentTypes: ContentCreatorContentType[] | null = null,
    initialContentPlaceholderTypes: ContentCreatorContentType[] | null = null,
    isOpenedFromPlaceholder = false,
  ): MatDialogRef<ContentCreatorComponent, Observable<ChatSendMessageRequest[]>> {
    return this.matDialog.open(ContentCreatorComponent, {
      id: `content-creator-modal${isOpenedFromPlaceholder ? '-from-placeholder' : ''}-${Math.random()}`,
      // Будем обрабатывать закрытие сами, чтобы правильно отрабатывал escape в галерее изображений
      disableClose: true,
      panelClass: 'modal-panel-rounded',
      width: `${MODAL_WIDTH}px`,
      minHeight: `${MODAL_MIN_HEIGHT}px`,
      maxHeight: `calc(100vh - ${MODAL_VERTICAL_MARGIN * 2}px)`,
      hasBackdrop: true,
      data: {
        chatTimeLineItem,
        initialContentTypes,
        initialContentPlaceholderTypes,
        isOpenedFromPlaceholder,
      },
    });
  }
}
