import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { StandaloneTaskItem } from '@api-clients/crm-api-client';
import { RecommendationsApiService } from '../../modules/recommendations/components/services/recommendations-api.service';
import { RecommendationsActionTypes, RecommendationsLoaded } from './recommendations.actions';
import { WorkerStateService } from '../../core/services';

@Injectable()
export class RecommendationsEffects {
  
  loadRecommendations$ = createEffect(() => this.actions$.pipe(
    ofType(RecommendationsActionTypes.LoadRecommendations),
    switchMap(() =>
      this.recommendationsApiService
        .getRecommendationsList(this.workerStateService.currentWorkerValue?.employeeId)
        .pipe(map((data: StandaloneTaskItem[]) => new RecommendationsLoaded([...data]))),
    ),
  ));

  constructor(
    private actions$: Actions,
    private recommendationsApiService: RecommendationsApiService,
    private workerStateService: WorkerStateService,
  ) {}
}
