import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { CrmTaskItem, StandaloneTaskItem } from '@api-clients/crm-api-client';
import { CrmCardListItem } from '@api-clients/crm-api-client/dist/models';
import * as Sentry from '@sentry/angular-ivy';
import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AppConfig } from '../environments/environment';
import { DealsFacade } from './+state/deals/deals.facade';
import { RecommendationsFacade } from './+state/recommendations/recommendations.facade';
import { WhatsappContactsFacade } from './+state/whatsapp-contacts/whatsapp-contacts.facade';
import { WorkerConfigFacade } from './+state/worker-config/worker-config.facade';
import { WorkerStateService } from './core/services';
import { DealStatus } from './models/deal-status';
import { TaskItem } from './models/ipcEvent';
import { ContentCreatorModalService } from './modules/content-creator/services/content-creator-modal.service';
import { SearchResultComponent } from './modules/deals/modules/deal-view/modules/search-tours/components/search-result/components/search-result.component';
import { DealItemsCounterService } from './modules/deals/services/deal-items-counter.service';
import { DealTasksService } from './modules/deals/services/deal-tasks.service';
import { HotOffersService } from './modules/hot-offers/hot-offers.service';
import { RecommendationsCounterService } from './modules/recommendations/components/services/recommendations-counter.service';
import { GoogleMapsLoaderService } from './modules/search/services/google-maps-loader.service';
import { SearchResultService } from './modules/search/services/search-result.service';
import { SearchApiService } from './modules/search/services/search/search-api.service';
import { ChatsCounterService } from './modules/whatsapp/services/chats-counter.service';
import { NotifyComponent } from './shared/notify/notify.component';
import { PreloaderComponent } from './ui-components/preloader/preloader.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PreloaderComponent, AsyncPipe, RouterOutlet, NotifyComponent, SearchResultComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  public deals$: Observable<CrmCardListItem[]> = this.dealsFacade.dealsList$;
  public tasks$: Observable<TaskItem[]>;
  public unreadChats$: Observable<number> = this.whatsappContactsFacade.unreadChats$;
  public recommendationsList$: Observable<StandaloneTaskItem[]> =
    this.recommendationsFacade.recommendationsList$;
  public isLoading: Subject<boolean> = new Subject<boolean>();

  showSearchResultComponent$ = this.searchResultService.showResultComponent$;
  mapLibrariesLoaded = signal<boolean>(false);
  private subscriptionHotOffersInit: Subscription;
  private subscriptionHotOffersInitTimer: Subscription;
  private searchFormParamsTimer: Subscription;
  private destroyRef = inject(DestroyRef);

  constructor(
    private dealsFacade: DealsFacade,
    private recommendationsFacade: RecommendationsFacade,
    private workerConfigFacade: WorkerConfigFacade,
    private whatsappContactsFacade: WhatsappContactsFacade,
    private dealItemsCounterService: DealItemsCounterService,
    private dealTasksService: DealTasksService,
    private chatsCounterService: ChatsCounterService,
    private workerStateService: WorkerStateService,
    private recommendationsCounterService: RecommendationsCounterService,
    private contentCreatorModalService: ContentCreatorModalService,
    private searchResultService: SearchResultService,
    private router: Router,
    private readonly mapsLoader: GoogleMapsLoaderService,
    private readonly hotOffersService: HotOffersService,
    private readonly searchApiService: SearchApiService,
  ) {
    this.isLoading.next(true);
    this.router.events.subscribe(e => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit(): void {
    this.contentCreatorModalService.listen();
    // this.applyInitialZoomLevel();
    if (this.workerStateService.accessStateValue) {
      this.loadDeals();
    }

    this.mapsLoader.loadApi().then(() => {
      this.mapLibrariesLoaded.set(true);
    });

    this.workerStateService.currentWorker$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        filter(worker => !!worker),
      )
      .subscribe(worker => {
        if (AppConfig.production) {
          Sentry.setUser({ id: worker.id, brandId: worker.brandId, username: worker.username });
        }

        this.subscriptionHotOffersInit?.unsubscribe();
        this.subscriptionHotOffersInit = this.hotOffersService.initState$().subscribe();

        this.subscriptionHotOffersInitTimer?.unsubscribe();
        this.subscriptionHotOffersInitTimer = interval(5 * 60 * 1000)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap(() => this.hotOffersService.initState$()),
          )
          .subscribe(() => {
            this.hotOffersService.flushData();
            this.hotOffersService.initState$().subscribe();
          });

        this.searchFormParamsTimer = interval(30 * 60 * 1000)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap(() => this.searchApiService.warmUpSearchFormParamsCache()),
          )
          .subscribe(() => {
            console.log('Параметры формы поиска обновлены');
          });

        this.searchApiService.warmUpSearchFormParamsCache().subscribe();
      });
  }

  ngOnDestroy(): void {
    this.subscriptionHotOffersInitTimer?.unsubscribe();
    this.searchFormParamsTimer?.unsubscribe();
  }

  // applyInitialZoomLevel() {
  //   const zoomLevel = parseFloat(localStorage.getItem('zoomLevel')) || 0;
  //   webFrame.setZoomLevel(zoomLevel);
  // }
  //
  loadDeals(): void {
    this.workerConfigFacade.loadConfig();

    if (localStorage.getItem('asideChatsEnabled') === null) {
      localStorage.setItem('asideChatsEnabled', 'true');
      this.workerConfigFacade.setAsideChatsScrollBar(true);
    } else {
      const asideChatsEnabled = localStorage.getItem('asideChatsEnabled') === 'true';
      this.workerConfigFacade.setAsideChatsScrollBar(asideChatsEnabled);
    }

    this.whatsappContactsFacade.loadInstance();
    this.dealsFacade.loadAll();
    this.deals$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(deals =>
          deals.filter(deal => deal.deal.status === DealStatus.new || deal.deal.status === DealStatus.work),
        ),
      )
      .subscribe(deals => {
        this.setDealsTotalNumber(deals);
      });
    this.tasks$ = this.deals$.pipe(
      switchMap(deals => {
        const tasks = deals
          .filter(deal => deal.nextTask !== undefined && deal.nextTask !== null)
          .map(deal => ({
            task: deal.nextTask as CrmTaskItem,
            clientName: deal.card.name,
            crmCardId: deal.card.id,
            status: deal.deal.status,
          }));

        return of(tasks);
      }),
    );

    this.unreadChats$.subscribe(chatsCount => this.setChatsTotalNumber(chatsCount));
    this.tasks$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(list => this.dealTasksService.sendTasksToNotifications(list));
    this.unreadChats$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(chatsCount => this.setChatsTotalNumber(chatsCount));
    if (this.workerStateService.currentWorkerValue.employeeId) {
      this.recommendationsFacade.loadRecommendations();
      this.recommendationsList$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(list => this.setRecommendationsTotalNumber(list.length));
    }
  }

  setDealsTotalNumber(deals) {
    this.dealItemsCounterService.dealsTotalNumber = deals.length;
  }

  setChatsTotalNumber(chatsCount) {
    this.chatsCounterService.chatsTotalNumber = chatsCount;
  }

  setRecommendationsTotalNumber(total: number) {
    this.recommendationsCounterService.recommendationsTotalNumber = total;
  }

  navigationInterceptor(event: any): void {
    switch (true) {
      case event instanceof NavigationStart: {
        this.isLoading.next(true);
        break;
      }

      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {
        this.isLoading.next(false);
        break;
      }
      default: {
        break;
      }
    }
  }
}
