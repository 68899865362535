import { Injectable, Injector, ElementRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MessageInfoComponent } from '../components/aside-chat-item/components/message-info/message-info.component';

@Injectable({ providedIn: 'root' })
export class OverlayService {
  private activeOverlayRef: OverlayRef;

  constructor(private overlay: Overlay, private injector: Injector) {}

  public open(config: { elementRef: ElementRef; data: any }) {
    if (this.activeOverlayRef) {
      this.activeOverlayRef.dispose();
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(config.elementRef)
      .withPositions([{ originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top' }])
      .withDefaultOffsetY(20);

    const customInjector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: 'name', useValue: config.data.name },
        { provide: 'lastMessage', useValue: config.data.lastMessage },
        { provide: 'shortMessage', useValue: config.data.shortMessage },
        { provide: 'hasUnreadMessages', useValue: config.data.hasUnreadMessages },
        { provide: 'phone', useValue: config.data.phone },
        { provide: 'crmId', useValue: config.data.crmId },
        { provide: 'dealId', useValue: config.data.dealId },
      ],
    });

    this.activeOverlayRef = this.overlay.create({
      positionStrategy,
      panelClass: 'message-info-overlay',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.activeOverlayRef.attach(new ComponentPortal(MessageInfoComponent, null, customInjector));
  }

  public close() {
    if (this.activeOverlayRef) {
      this.activeOverlayRef.dispose();
      this.activeOverlayRef = null;
    }
  }
}
