<div class="result-container">
  @if (isShowTours()) {
    <div class="tours-container">
      <app-search-result-tours [resultGroups$]="resultGroups$"
                               [mapVisibleHotelIds$]="mapVisibleHotelIds$"
                               [initSearchRequest]="initSearchRequest()" />
    </div>
  }
  <div class="map-container">

    <app-search-result-controls />
    <app-search-result-post-filters [minTourPrice]="minTourPrice()"
                                    [maxTourPrice]="maxTourPrice()"
                                    [starsList]="starsList()"
                                    [initSearchRequest]="initSearchRequest()" />

      <app-search-result-map [resultGroups$]="resultGroups$"
                             [initSearchRequest]="initSearchRequest()"
                             (boundsHotelIds)="mapBoundsHotelIds($event)" />
  </div>

  <div class="tours-container-control" [class.open]="isShowTours()" (click)="toggleShowTours()">
    <img [class.rotate]="isShowTours()" class="toggle-groups-icon"
         src="assets/icons/favorite-hotels/chevron-right.svg">
  </div>
</div>
