import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';

@Component({
  selector: 'app-frontend-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class InputComponent {
  placeholder = input<string>('');
  @Output() inputEvent = new EventEmitter<string>();

  handleInput(event: Event): void {
    this.inputEvent.emit((event.target as HTMLInputElement).value);
  }
}
