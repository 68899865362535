<span class="status-indicator"
      [ngClass]="{
            'status-online': status === managerSipStateEnum.online,
            'status-busy': status !== managerSipStateEnum.online
          }">
    </span>
<span class="status-text">
      {{ status === managerSipStateEnum.online ?
  ('LAYOUT.TOP.MENU.STATUS.FREE' | translate) :
  ('LAYOUT.TOP.MENU.STATUS.BUSY' | translate) }}
</span>
