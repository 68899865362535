<div class="progress-container">
  @if (searchInProgress$ | async) {
    <app-line-progress [animationDuration]="'10s'" />
  }
</div>

<div class="filters-and-sort-container">
  <div class="filters-and-sort">
    <div class="search-control-container">
      <input #searchQuery
        (input)="onSearchUpdated(searchQuery.value)"
        type="text"
        class="search-control"
        placeholder="Поиск по названию отелю">
    </div>

    <app-search-result-groups-sorts (selectedSort)="selectedSort($event)" />
  </div>
</div>

@if (showToursNotFound()) {
  <div class="tours-not-found">
    <app-alert-label>
      <p>Туры не найдены, попробуйте изменить параметры поиска</p>
    </app-alert-label>
  </div>
}

<div class="groups">
  <virtual-scroller #scroll [items]="visibleGroups()" [checkResizeInterval]="2000">
    @for (group of scroll.viewPortItems; track group.hotel.id) {
      <app-search-hotel
        (showHotelOnMap)="showHotelOnMap($event)"
        [contentCreatorType]="contentCreatorType.ManagerPricesCalendar"
        [tourSelectMode]="tourSelectMode"
        [initSearchRequest]="initSearchRequest"
        [allowResearch]="true"
        [allowReCalculate]="true"
        [researchAddExtendsTours]="true"
        [mapPointerVisible]="true"
        [showDiscount]="false"
        [showConversion]="true"
        [hotelOnMap]="(mapVisibleHotelIds().length === 0 && !hasFilters()) || mapVisibleHotelIds().includes(group.hotel.id)"
        [tags]="tags().get(group.hotel.id) || []"
        [hotel]="group.hotel"
        [initSearchTours]="group.tours" />
    }
  </virtual-scroller>
</div>
