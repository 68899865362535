<form class="container" [formGroup]="form">
  <div class="filter">
    <div class="name">Цена за тур, KZT</div>
    <div class="values">
      <input type="text" class="input-value"
             [attr.placeholder]="minTourPrice()| number: '1.0-0'"
             appAutofocus
             appNumberFormat
             formControlName="from">
      <input type="text" class="input-value"
             [attr.placeholder]="maxTourPrice()| number: '1.0-0'"
             formControlName="to"
             appNumberFormat>
    </div>
  </div>
  <div class="controls">
    <button type="button" class="btn btn-reset" (click)="reset()">Сбросить</button>
    <button type="button" class="btn btn-apply" (click)="apply()">Применить</button>
  </div>
</form>
