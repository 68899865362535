<div (clickOutside)="clickOutside()">
  <div class="label">Количество ночей</div>
  <div class="days-list">
    @for (daysCount of availableNightsInDirection(); track daysCount) {
      <div
        [class.active]="daysCount === nightsFrom || daysCount === nightsTo"
        [class.range]="isInRange(daysCount)"
        (click)="selectDaysCount(daysCount)"
        (mouseenter)="onMouseEnter(daysCount)"
        (mouseleave)="onMouseLeave()"
        class="day">{{ daysCount }}
      </div>
    }
  </div>
</div>
