<div class="logo">
  @if (isDevVersion) {
    <div class="dev-logo" (click)="changeVersionToProduction()" title="Перейти на prod версию">
      <img src="assets/img/logo-dev.svg" alt="logo" />
      <span class="label-dev">DEV</span>
    </div>
  } @else {
    <img src="assets/img/logo.svg" alt="logo" />
  }
</div>

@if ((zenModeOn$ | async) === false) {
  <div class="top-nav left-nav">
    <div class="nav-item border-left">
      <a (click)="showManagersList()">
        {{ 'LAYOUT.TOP.MENU.MANAGERS' | translate }}
      </a>
    </div>
    <div class="nav-item">
      <a (click)="tourDBOpened()" libExternalLink [externalURL]="adminUrl + '/TourDb?active'">
        {{ 'LAYOUT.TOP.MENU.TP_LIST' | translate }}
      </a>
    </div>
    <div class="nav-item">
      <app-network-status />
    </div>
  </div>
}

<nav class="top-nav">
  <div class="nav-item">
    <app-search-form />
  </div>
  @if ((zenModeOn$ | async) === false) {
    <div class="nav-item border-left padding-right">
      <app-commission />
    </div>
  }

  <div class="nav-item border-left">
    <app-worker-status />
  </div>

  @if ((zenModeOn$ | async) === false) {
    <div class="nav-item">
      <div class="icon clickable" (click)="showNotificationsPopup()">
        <img src="./assets/icons/top-menu/notifications.svg" alt="notifications" />
        <div class="count">
          <app-notifications-center-count />
        </div>
      </div>
    </div>
  }
  @if ((zenModeOn$ | async) === false) {
    <div class="nav-item border-right">
      <button type="button" class="btn-profile" (click)="toggleMenu()">
        <div class="icon">
          <img src="./assets/icons/top-menu/right-side-menu/worker-avatar.svg" alt="avatar" />
        </div>
        <div class="icon arrow" [ngClass]="{ rotate: (popupOpen$ | async) }">
          <img src="./assets/icons/top-menu/right-side-menu/arrow-down.svg" alt="arrow" />
        </div>
      </button>
    </div>
  }

  @if (zenModeOn$ | async) {
    <div class="nav-item border-left padding-right no-gap">
      <button (click)="openZenmodeList()">
        <img class="icon clickable" src="assets/icons/zenmode/book.svg" alt="help" />
      </button>
    </div>
  }

  @if (zenModeOn$ | async) {
    <div class="nav-item border-right border-left" id="help-intro">
      <button (click)="showZenModeIntro()">
        <img class="icon clickable" src="assets/icons/zenmode/help.svg" alt="help" />
      </button>
    </div>
  }
  @if ((isAvailableZenmode$ | async) || (zenModeOn$ | async)) {
    <div class="nav-item last" id="toggle-intro">
      <app-zenmode-toggle></app-zenmode-toggle>
    </div>
  }
</nav>
