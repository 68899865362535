<div class="tourists" [formGroup]="touristsForm">
  <div class="label">Туристы</div>

  <div class="counters">
    <div class="counter">
      <img class="tourist-icon" src="assets/icons/favorite-hotels/search-form-adults.svg">

      <div class="control-container">
        <button type="button" class="btn-control" (click)="decAdults()">-</button>
        <div class="control-value">{{ adults }}</div>
        <button type="button" class="btn-control" (click)="incAdults()">+</button>
      </div>
    </div>
    <div class="counter">
      <img class="tourist-icon" src="assets/icons/favorite-hotels/search-form-children.svg">

      <div class="control-container">
        <button type="button" class="btn-control" (click)="decChild()">-</button>
        <div class="control-value">{{ child }}</div>
        <button type="button" class="btn-control" (click)="incChild()">+</button>
      </div>
    </div>
  </div>

  @if (childControl.controls.length > 0) {
    <div formArrayName="childAges" class="child-ages-controls">
      @for (childAge of childControl.controls; track childAge; let i = $index) {
        <input [formControlName]="i"
               appAutofocus
               type="text"
               class="child-ages-form-control">
      }
    </div>
  }

  @if (canSearchSplitRooms) {
    <div class="checkbox">
      <label>
        <input type="checkbox" formControlName="splitRooms" (change)="changeSplitRooms()">
        Разделить на два номера
      </label>
    </div>
  }
</div>
