import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ManagerSearchHotelTemplate } from '@api-clients/crm-api-client/models/manager-search-hotel-template';

@Component({
  selector: 'app-search-form-templates-dropdown-item',
  template: ` <div class="dropdown-item" [class.active]="isActive">
    @if (isActive) {
      <img src="assets/icons/favorite-hotels/search-form-check.svg" class="selected-icon" />
    }
    {{ template.name }}
  </div>`,
  styles: [
    `
      .dropdown-item {
        color: #21304e;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 8px 8px 8px 28px;
        gap: 8px;

        cursor: pointer;
        position: relative;

        &.active {
          background-color: #0070d8;
          color: #fff;
          cursor: default;
        }

        &:hover {
          background-color: #0070d8;
          color: #fff;
        }
      }

      .selected-icon {
        position: absolute;
        top: calc(50% - 4px);
        left: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class SearchFormTemplatesDropdownItemComponent {
  @Input() isActive = false;
  @Input() template!: ManagerSearchHotelTemplate;
}
