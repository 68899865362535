@if (asideChatList$ | async; as list) {
  <div class="chats-label">
    {{'LAYOUT.ASIDE.CHATS' | translate }}
  </div>
  <div class="scroll-wrap">
    <div class="wrap" >
      @for (chat of list; track chat) {
        <app-aside-chat-item
          [chat]="chat"
          (mouseleave)="onMouseLeave()"
          >
        </app-aside-chat-item>
      }
    </div>
  </div>
}



