<div class="container">
  <div class="control">
    <button type="button" class="btn-control btn-close"
            matTooltip="Вырнуться в чат"
            matTooltipClass="control-tooltip"
            [class.with-avatar]="clientAvatar()"
            (click)="hideSearchResult()">
      <img src="assets/icons/favorite-hotels/double-chevron.svg" alt="">
      @if (clientAvatar()) {
        <div class="avatar-container">
          <img [src]="clientAvatar()" class="avatar-img" alt="">
        </div>
      }
    </button>
  </div>
  <div class="control">
    <app-search-result-control-search-form />
  </div>
</div>
