<div class="notify"
     (mouseover)="showDeleteButton()"
     (mouseout)="hideDeleteButton()"
  [ngClass]="{
  'red': notification.type === NotificationType.TourPackageClaimRejected,
  'yellow': notification.type === NotificationType.TourPackageClaimOutdated,
  'hovered': isHovered,
  'white': !notification.isNew
  }
"
>
  <div class="header">
    <img src="./assets/icons/notifications-center/{{notification.type}}.svg" alt="" class="icon">
    <ng-container *ngIf="notification.type === NotificationType.TourPackageNewPayment; else header">
      <h1 class="title" *ngIf="notification.isFullyPaid; else partial">
      {{'PAGES.NOTIFICATIONS_CENTER.PAYMENT.FULL' | translate}}
      </h1>
      <ng-template #partial>
        <h1 class="title">
          {{'PAGES.NOTIFICATIONS_CENTER.PAYMENT.PARTIAL' | translate}}
        </h1>
      </ng-template>
    </ng-container>

    <ng-template #header>
      <h1>
        {{'PAGES.NOTIFICATIONS_CENTER.' + notification.type | translate}}
      </h1>
    </ng-template>

    <span *ngIf="notification.isNew" class="new">new</span>
    <div class="delete" (click)="deleteById(notification.id)">
      <img src="./assets/icons/notifications-center/delete.svg"
           alt="delete">
    </div>
  </div>
  <div class="notify-content" [ngSwitch]="notification.type">


      <ng-container
        *ngSwitchCase="NotificationType.DealAssigned">
        <app-deal-assigned
        [notification]="notification"
        (dealViewOpened)="openDealView($event)"
        >
        </app-deal-assigned>
      </ng-container>
      <ng-container
        *ngSwitchCase="NotificationType.TourPackageClaimConfirmed">
        <app-tour-package-claim-confirmed
        [notification]="notification"
        >
        </app-tour-package-claim-confirmed>
      </ng-container>
      <ng-container
        *ngSwitchCase="NotificationType.TourPackageClaimRejected">
        <app-tour-package-claim-confirmed
        [notification]="notification"
        >
        </app-tour-package-claim-confirmed>
      </ng-container>

    <ng-container
      *ngSwitchCase="NotificationType.TourPackageDocsListChanged">
      <app-tour-package-docs-list-changed
        [notification]="notification"
      >

      </app-tour-package-docs-list-changed>
    </ng-container>
    <ng-container
      *ngSwitchCase="NotificationType.TourPackageDocsReady">
      <app-tour-package-docs-ready
        [notification]="notification"
      >

      </app-tour-package-docs-ready>
    </ng-container>

    <ng-container
      *ngSwitchCase="NotificationType.TourPackageNewPayment">
      <app-tour-package-new-payment
        [notification]="notification"
      >

      </app-tour-package-new-payment>
    </ng-container>

    <ng-container
      *ngSwitchCase="NotificationType.TourPackageClaimOutdated">
      <app-tour-package-claim-outdated
        [notification]="notification"
      >

      </app-tour-package-claim-outdated>
    </ng-container>
    <div class="second-column">
      <div class="time">
        {{notification.createdAt | timeSince}}
      </div>
    </div>

  </div>

</div>
