import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationListResponse } from '@api-clients/crm-api-client/models';
import { filter, first, map, switchMapTo } from 'rxjs/operators';
import { NotificationType } from '@api-clients/crm-api-client/models/notification-type';
import { NotificationsCenterApiService } from '../../services/notifications-center-api.service';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import {
  ALL_NOTIFICATIONS_CLEAR,
  ONE_NOTIFICATIONS_DELETE,
} from '../../../../core/services/amplitude/amplitudeEvents';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { NotifyCenterItemComponent } from '../notify-center-item/notify-center-item.component';

@UntilDestroy()
@Component({
  selector: 'app-notifications-center-list',
  templateUrl: './notifications-center-list.component.html',
  styleUrls: ['./notifications-center-list.component.scss'],
  standalone: true,
  imports: [TranslateModule, AsyncPipe, NotifyCenterItemComponent],
})
export class NotificationsCenterListComponent implements OnInit {
  public readonly screenType: ScreenTypes = ScreenTypes.NOTIFICATIONS_CENTER;
  public event$ = new BehaviorSubject(true);
  public listActual$: Observable<NotificationListResponse>;
  constructor(
    private notificationsCenterApi: NotificationsCenterApiService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.listActual$ = this.event$.pipe(switchMapTo(this.notificationsCenterApi.getListActual()));
  }

  ngOnInit(): void {
    this.fetchData();
    this.markAsViewed();
  }

  clearNotificationsCenter() {
    this.notificationsCenterApi
      .deleteAll()
      .pipe(first())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchData();
        this.amplitudeTrackService.trackEvent(ALL_NOTIFICATIONS_CLEAR, { screenType: this.screenType });
      });
  }
  deleteById(id: number, type: NotificationType) {
    this.notificationsCenterApi
      .deleteById(id)
      .pipe(first())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchData();
        this.amplitudeTrackService.trackEvent(ONE_NOTIFICATIONS_DELETE, {
          notificationType: type,
          screenType: this.screenType,
        });
      });
  }

  fetchData() {
    this.event$.next(true);
    this.listActual$ = this.listActual$.pipe(
      switchMapTo(this.notificationsCenterApi.getListActual()),
      map(response => ({
        ...response,
        list: response.list.filter(item => item.type !== 'deal-assigned'),
      })),
    );
    this.notificationsCenterApi.manualRefreshCountNew();
  }
  markAsViewed() {
    this.notificationsCenterApi
      .markAsViewed()
      .pipe(first())
      .pipe(untilDestroyed(this))
      .subscribe(() => this.notificationsCenterApi.manualRefreshCountNew());
  }
}
