<div class="network-status-container"
     [class.offline]="isOffline()"
     [class.slow-connection]="!isOffline() && isSlowConnection()">
  @if (isOffline()) {
    Нет подключения к интернету
  }

  @if (!isOffline() && isSlowConnection()) {
    Медленное соединение
  }
</div>
