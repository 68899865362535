import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountResponse, NotificationListResponse } from '@api-clients/crm-api-client/models';
import { DefaultResponse } from '@api-clients/crm-api-client';
import { NotificationCenterDeleteByIdRequest } from '@api-clients/crm-api-client/models/notification-center-delete-by-id-request';
import { delayWhen, retryWhen } from 'rxjs/operators';
import { BehaviorSubject, timer } from 'rxjs';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class NotificationsCenterApiService {
  getCountNewEvent$ = new BehaviorSubject(true);

  constructor(private http: HttpClient) {}

  manualRefreshCountNew() {
    this.getCountNewEvent$.next(true);
  }

  getListActual() {
    return this.http
      .get<ApiResponse<NotificationListResponse>>(`${AppConfig.apiUrl}/notifications-center/list-actual`)
      .pipe(apiResponsePipe);
  }

  getCountNew() {
    return this.http
      .get<ApiResponse<CountResponse>>(`${AppConfig.apiUrl}/notifications-center/count-new`)
      .pipe(retryWhen(errors => errors.pipe(delayWhen(() => timer(60 * 1000)))))
      .pipe(apiResponsePipe);
  }

  deleteById(notificationId: number) {
    const body: NotificationCenterDeleteByIdRequest = {
      notificationId,
    };
    return this.http.post<ApiResponse<DefaultResponse>>(
      `${AppConfig.apiUrl}/notifications-center/delete-by-id`,
      body,
    );
  }

  deleteAll() {
    return this.http.post<ApiResponse<DefaultResponse>>(
      `${AppConfig.apiUrl}/notifications-center/delete-all`,
      {},
    );
  }

  markAsViewed() {
    return this.http.post<ApiResponse<DefaultResponse>>(
      `${AppConfig.apiUrl}/notifications-center/mark-all-as-viewed`,
      {},
    );
  }
}
