import { ChangeDetectionStrategy, Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SlideToggleComponent } from '../../../../../../../../../ui-components/slide-toggle/components/slide-toggle.component';

@Component({
  selector: 'app-search-form-not-gds',
  template: ` <app-slide-toggle
    [defaultValue]="defaultValue"
    [labelText]="'Не GDS'"
    (change)="onChange($event)"
  />`,
  styles: `
    :host {
      display: block;
      padding: 5px 0 0 0;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SlideToggleComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFormNotGdsComponent),
      multi: true,
    },
  ],
})
export class SearchFormNotGdsComponent implements ControlValueAccessor {
  defaultValue = false;

  onChange = (value: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(values: boolean): void {
    this.defaultValue = values;
  }
}
