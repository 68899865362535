<div class="right-side-menu"
     [delayClickOutsideInit]="true"
     (clickOutside)="close()">
  @for (item of menuItems; let i = $index; let isLast = $last; track item.link) {
    <app-right-side-menu-item
      [item]="item"
      [isLast]=isLast
      (close)="close()"
    />
  }
</div>
