@if ((errorMessage$ | async) === null) {
  @if (currentEmployeePresentations.length) {
    <div class="presentation-group-title">
      {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.CURRENT_EMPLOYEE_PRESENTATIONS' | translate }}
    </div>
    @for (presentation of currentEmployeePresentations; track presentation) {
      <app-audio-presentation-player
        [presentation]="presentation"
        [activePresentation]="activePresentation"
        (setActivePresentation)="setActivePresentation($event)"
        />
    }
  }
  @if (otherEmployeesPresentations.length) {
    <div class="presentation-group-title">
      {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.OTHER_EMPLOYEES_PRESENTATIONS' | translate }}
    </div>
    <div class="presentation-group-title info">
      {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.OTHER_EMPLOYEES_PRESENTATIONS_INFO' | translate }}
    </div>
    @if (currentEmployeeHasVoiceModel) {
      @for (presentation of otherEmployeesPresentations; track presentation) {
        <app-audio-presentation-player
          [presentation]="presentation"
          [activePresentation]="activePresentation"
          (setActivePresentation)="setActivePresentation($event)"
          (sendAsText)="setSendAsText(presentation, $event)"
          />
      }
    }
    @if (!currentEmployeeHasVoiceModel) {
      <app-alert-label [type]="dangerAlertLabelType">
        {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.VOICE_MODEL_NOT_FOUND' | translate }}<br/><br/>
        {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.VOICE_MODEL_NOT_FOUND_HELP' | translate }}<br/>
        {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.VOICE_MODEL_NOT_FOUND_HELP_LENGTH' | translate }}<br/><br/>
        <a libExternalLink [externalURL]="adminUrl + '/career/hotelPresentations'">
          {{ 'PAGES.CONTENT_CREATOR.CONTENT_ITEMS.AUDIO_PRESENTATION.MY_PRESENTATIONS' | translate }}
        </a>
      </app-alert-label>
    }
  }
}

@if (errorMessage$ | async; as errorMessage) {
  <app-alert-label [type]="dangerAlertLabelType">
    {{errorMessage}}
  </app-alert-label>
}
