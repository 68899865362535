<div class="container">
  <div class="items">
    @for (item of items; track item.id) {
      <div class="checkbox">
        <label>
          <input type="checkbox" [(ngModel)]="item.selected" (change)="onCheckboxChange()">
          <app-hotel-stars [value]="item.id" />
        </label>
      </div>
    }
  </div>
</div>
