import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZenmodeComponent } from '../components/zenmode-list/zenmode.component';

@Injectable({
  providedIn: 'root',
})
export class ZenmodeListPopupService {
  constructor(public matDialog: MatDialog) {}

  showPopup(): void {
    this.matDialog.open(ZenmodeComponent, {
      maxHeight: '675px',
    });
  }
}
