import { RecommendationsState } from './recommendations.state';
import { RecommendationsAction, RecommendationsActionTypes } from './recommendations.actions';

export const recommendationsInitialState: RecommendationsState = {
  list: [],
  loaded: false,
};

export function recommendationsReducer(
  state: RecommendationsState = recommendationsInitialState,
  action: RecommendationsAction,
): RecommendationsState {
  switch (action.type) {
    case RecommendationsActionTypes.RecommendationsLoaded:
      state = {
        ...state,
        list: action.payload,
        loaded: true,
      };
      break;
    default:
      return state;
  }
  return state;
}
