<div id="map-search-hotel" class="map"
     [style.width.px]="windowWidth()"
     [style.height.px]="windowHeight()">

  <div class="map-controls">
    <app-search-result-map-search-on-place [map]="map()" />
  </div>

  <google-map
    [width]="windowWidth() + 'px'"
    [height]="windowHeight() + 'px'"
    [options]="mapOptions"
    [center]="mapCenter"
    (zoomChanged)="onZoomChanged()"
    (boundsChanged)="onMapChanged()" />
</div>

<div #infoWindowContainer></div>
