import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchHistoryService {
  private historyKey = 'search_history';
  private maxHistoryLength = 3;

  saveSearchQuery(query: string): void {
    if (!query) return;

    const currentHistory = this.getSearchHistory();
    const existingQueryIndex = currentHistory.findIndex(item => item.toLowerCase() === query.toLowerCase());

    if (existingQueryIndex === -1) {
      currentHistory.unshift(query);

      if (currentHistory.length > this.maxHistoryLength) {
        currentHistory.pop();
      }
    } else {
      currentHistory.splice(existingQueryIndex, 1);
      currentHistory.unshift(query);
    }

    localStorage.setItem(this.historyKey, JSON.stringify(currentHistory));
  }

  getSearchHistory(): string[] {
    const history = localStorage.getItem(this.historyKey);
    return history ? JSON.parse(history) : [];
  }

  clearSearchHistory(): void {
    localStorage.removeItem(this.historyKey);
  }
}
