import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateWhatsappScheduledMessageRequest,
  DefaultResponse,
  DeleteWhatsappScheduledMessageRequest,
  ListWhatsappScheduledMessageForm,
  RescheduleWhatsappScheduledMessageRequest,
  WhatsappScheduledMessage,
} from '@api-clients/crm-api-client';
import { delay, Observable, of } from 'rxjs';
import { AppConfig } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WhatsappScheduleMessagesService {
  private baseUrl = `${AppConfig.crmApiUrl}/whatsapp/scheduled-messages`;

  constructor(private http: HttpClient) {}

  // Получение списка запланированных сообщений
  getScheduledMessages(request: ListWhatsappScheduledMessageForm): Observable<WhatsappScheduledMessage[]> {
    return this.http.post<WhatsappScheduledMessage[]>(`${this.baseUrl}/list`, request);
  }

  // Создание нового запланированного сообщения
  createScheduledMessage(request: CreateWhatsappScheduledMessageRequest): Observable<DefaultResponse> {
    // return of({ success: true, message: '' }).pipe(delay(2000));
    return this.http.post<DefaultResponse>(`${this.baseUrl}/create`, request);
  }

  // Перенос запланированного сообщения на другую дату/время
  rescheduleMessage(request: RescheduleWhatsappScheduledMessageRequest): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.baseUrl}/reschedule`, request);
  }

  // Удаление запланированного сообщения
  deleteScheduledMessage(request: DeleteWhatsappScheduledMessageRequest): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.baseUrl}/delete`, request);
  }
}
