import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchResultFiltersService } from '../../../../services/search-result-filters.service';

export interface CheckboxItem {
  id: number;
  selected: boolean;
}

@Component({
  selector: 'app-favorite-hotels-map-filter-rating',
  templateUrl: './favorite-hotels-map-filter-rating.component.html',
  styleUrl: './favorite-hotels-map-filter-rating.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule],
})
export class FavoritesHotelsMapFilterRatingComponent implements OnInit {
  @Input() selectedIds: number[] = [];

  items: CheckboxItem[] = [];

  private ratingList = [9.0, 8.5, 8.0, 7.5];

  constructor(private readonly mapService: SearchResultFiltersService) {}

  ngOnInit() {
    this.items = this.ratingList.map(stars => {
      const selected = this.selectedIds.includes(stars);

      return {
        id: stars,
        selected,
      };
    });
  }

  onCheckboxChange() {
    const selectedItems = this.items.filter(item => item.selected).map(item => item.id);

    this.mapService.applyHotelRatingFilter(selectedItems);
  }
}
