import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ManagerSearchHotelTemplateList } from '@api-clients/api-client';
import { ManagerSearchHotelTemplate } from '@api-clients/api-client/models/manager-search-hotel-template';
import { Subscription } from 'rxjs';

import { PopupService } from '../../../../../../../../../shared/services/popup-service.service';
import { SearchFormTemplatesDropdownComponent } from './dropdown/search-form-templates-dropdown.component';

@Component({
  selector: 'app-search-form-templates',
  templateUrl: './search-form-templates.component.html',
  styleUrls: ['./search-form-templates.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFormTemplatesComponent),
      multi: true,
    },
  ],
})
export class SearchFormTemplatesComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() set hotelTemplateList(templates: ManagerSearchHotelTemplateList) {
    this.templates = templates;
    this.updateActiveTemplate();
  }

  @ViewChild('templates', { read: ElementRef }) templatesRef: ElementRef;

  activeTemplate = signal<ManagerSearchHotelTemplate>(undefined);

  private templates: ManagerSearchHotelTemplateList;
  private componentRef: ComponentRef<SearchFormTemplatesDropdownComponent>;
  private componentRefSelected: Subscription;
  private valueChangesSubscription: Subscription;
  private selectedTemplateId: number;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly popupService: PopupService,
  ) {}

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: number): void {
    if (value !== undefined) {
      this.selectedTemplateId = value;
      this.updateActiveTemplate();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit() {}

  ngOnDestroy() {
    this.valueChangesSubscription?.unsubscribe();
    this.destroyDropdownComponent();
  }

  openDropdown() {
    this.destroyDropdownComponent();

    this.componentRef = this.popupService.showPopup(
      SearchFormTemplatesDropdownComponent,
      {},
      {
        anchorElement: this.templatesRef,
      },
    );

    this.componentRef.instance.basicTemplates = this.templates.basicTemplates;
    this.componentRef.instance.managerTemplates = this.templates.managerTemplates;
    this.componentRef.instance.activeTemplate = this.activeTemplate();
    this.componentRefSelected = this.componentRef.instance.selected.subscribe(template => {
      this.activeTemplate.set(template);

      this.onChange(this.activeTemplate().id);
      this.cdRef.detectChanges();
      this.popupService.closeAllModals();
    });
  }

  private updateActiveTemplate(): void {
    if (!this.templates) {
      return;
    }

    const basicTemplates = this.templates.basicTemplates;
    const managerTemplates = this.templates.managerTemplates;

    const allTemplates = basicTemplates.concat(managerTemplates);
    const template = allTemplates.find(v => v.id === this.selectedTemplateId);
    if (template) {
      this.activeTemplate.set(template);
    }
  }

  private destroyDropdownComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.componentRefSelected?.unsubscribe();
  }
}
