import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxItem } from '../checkbox-list/search-form-checkbox-list.component';
import { SearchFormStarsComponent } from './search-form-stars.component';

@Component({
  selector: 'app-search-form-stars-list',
  templateUrl: './search-form-stars-list.component.html',
  styleUrls: ['./search-form-stars-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchFormStarsComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchFormStarsListComponent),
    },
  ],
})
export class SearchFormStarsListComponent implements OnInit, ControlValueAccessor {
  items = signal<CheckboxItem[]>([]);

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  onChange = (value: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  writeValue(values: number[]): void {
    if (values.length) {
      this.items.update(items => {
        items.forEach(item => {
          const id: number = item.id as number;
          item.selected = values.indexOf(id) !== -1;
        });
        return items;
      });
    }

    this.cdRef.detectChanges();
  }

  ngOnInit() {
    const items: CheckboxItem[] = [];
    for (let i = 5; i >= 1; i--) {
      items.push({
        id: i,
        name: i.toString(),
        selected: false,
      });
      this.items.set(items);
    }
  }

  onCheckboxChange(id: number) {
    const selectedItemIds: number[] = [];

    this.items.update(values => {
      values.forEach(v => {
        if (v.id === id) {
          v.selected = !v.selected;
        }

        if (v.selected) {
          selectedItemIds.push(v.id as number);
        }
      });
      return values;
    });

    this.onChange(selectedItemIds);
  }
}
