import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealItemsCounterService {
  private dealsNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  dealsNumber$: Observable<number> = this.dealsNumber.asObservable();

  set dealsTotalNumber(total: number) {
    this.dealsNumber.next(total);
  }
}
