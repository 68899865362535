<div class="arrow left"
  [class.loading]="isLoadingLeft"
  [class.disabled]="isDisabledBackButton$ | async"
  (click)="previousPage()">
  @if (isLoadingLeft) {
    <img src="assets/icons/zenmode/pulse.svg" alt="pulse">
  } @else {
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M21 12L3 12M3 12L10 4.5M3 12L10 19.5" stroke="#007CF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  }
  <span class="text">{{ prevText | async }}</span>
</div>

<div class="arrow right"
  [class.loading]="isLoadingRight"
  [class.disabled]="isDisabledNextButton$ | async"
  (click)="nextPage()" id="paginate-intro">
  <span class="text">{{ nextText | async }}</span>
  @if (isLoadingRight) {
    <img src="assets/icons/zenmode/pulse.svg" alt="pulse">
  } @else {
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3 12L21 12M21 12L14 4.5M21 12L14 19.5" stroke="#007CF0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  }

</div>

