<div class="modal-managers-list">
  <div style="display: flex">
    <h2 style="margin-right: 1rem" class="modal-managers-list-title">
      {{ 'MODALS.MANAGERS_LIST.TITLE' | translate }}
    </h2>
    <app-frontend-input placeholder="Поиск" (inputEvent)="onSearchQueryChange($event)" />
  </div>
  <span (click)="closeModal()" class="modal-close-icon">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.705 0.291674C11.3135 -0.0976171 10.6801 -0.0976189 10.2898 0.294039L6 4.58452L1.71024 0.294039C1.31994 -0.0976189 0.686453 -0.0976171 0.294965 0.291674C-0.0977095 0.679782 -0.0977347 1.31163 0.291381 1.70329L4.58707 5.99852L0.291381 10.2937C-0.0977347 10.6854 -0.0977095 11.3173 0.294965 11.7054C0.489522 11.8994 0.744584 11.9976 1.00083 11.9976C1.25708 11.9976 1.51569 11.9006 1.71024 11.703L6 7.41252L10.2898 11.7054C10.4843 11.903 10.7429 12 10.9992 12C11.2554 12 11.5105 11.903 11.705 11.7089C12.0977 11.3208 12.0977 10.6878 11.7086 10.2973L7.41293 5.99852L11.705 1.70329C12.0977 1.31163 12.0977 0.679782 11.705 0.291674Z"
        fill="#71899E"
      />
    </svg>
  </span>
  @if (officeList?.length || currentQuery()) {
    <div class="managers-list-wrapper">
      @for (office of officeList; track office; let isFirstOffice = $first) {
        <div class="office-item">
          <h5 class="office-item-title">{{ office }}</h5>
          <ul>
            @for (manager of groupedByOffice['offices'][office]; track manager.id; let isFirstManager = $first) {
              <li
                class="manager"
                [attr.manager-id]="manager?.id"
                [attr.manager-username]="manager?.username"
                [attr.id]="isFirstOffice && isFirstManager ? 'highlightNewFeature' : null"
              >
                <span class="manager-status manager-status--{{ manager?.state }}"></span>
                <app-worker-name
                  [name]="manager?.name | highlight: currentQuery()"
                  [crmCardId]="manager?.crmCardId"
                  (nameClicked)="cardOpened()"
                >
                </app-worker-name>
                @if (manager?.sip?.innerPhone) {
                  <span
                    class="manager-inner-phone"
                    [innerHTML]="getFormattedManagerSip(manager.sip.innerPhone) | highlight: currentQuery()"
                  ></span>
                }
              </li>
            }
          </ul>
        </div>
      }
      <ng-container
        *ngIf="
          groupedByOffice && groupedByOffice.callCenter.length && groupedByOffice.callCenter as callCenter
        "
      >
        <div class="office-item">
          <h5 class="office-item-title">{{ 'MODALS.MANAGERS_LIST.CALL_CENTER' | translate }}</h5>
          <ul>
            @for (manager of callCenter; track manager.id) {
              <li class="manager">
                <span class="manager-status manager-status--{{ manager?.state }}"></span>
                <span class="manager-name" [innerHTML]="manager?.name | highlight: currentQuery()"></span>
                @if (manager?.sip?.innerPhone) {
                  <span
                    class="manager-inner-phone"
                    [innerHTML]="getFormattedManagerSip(manager.sip.innerPhone) | highlight: currentQuery()"
                  ></span>
                }
              </li>
            }
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="managers-status-legend">
      <h4 class="managers-status-legend-title">
        {{ 'MODALS.MANAGERS_LIST.MANAGER_STATUS.TITLE' | translate }}
      </h4>
      <ul class="status-legend-items">
        <li class="status-legend-item">
          <span class="managers-status managers-status--available"></span>
          <span class="status-legend-title">{{
              'MODALS.MANAGERS_LIST.MANAGER_STATUS.AVAILABLE' | translate
            }}</span>
        </li>
        <li class="status-legend-item">
          <span class="managers-status managers-status--busy"></span>
          <span class="status-legend-title">{{
              'MODALS.MANAGERS_LIST.MANAGER_STATUS.BUSY' | translate
            }}</span>
        </li>
        <li class="status-legend-item">
          <span class="managers-status managers-status--offline"></span>
          <span class="status-legend-title">{{
              'MODALS.MANAGERS_LIST.MANAGER_STATUS.OFFLINE' | translate
            }}</span>
        </li>
      </ul>
    </div>
  } @else {
    <app-preloader></app-preloader>
  }
</div>
