import { DealsListAction, DealsListActionTypes } from './deals-list.actions';
import { DealsListState } from './deals-list.interface';

export const dealsListInitialState: DealsListState = {
  currentStage: null,
  currentCategory: null,
  listMaximizedTourPackages: [],
  lastSelectedListItemId: null,
  isAllowShowOtherDeals: true,
};

export function dealsListReducer(
  state: DealsListState = dealsListInitialState,
  action: DealsListAction,
): DealsListState {
  switch (action.type) {
    case DealsListActionTypes.SetCurrentStage:
      state = {
        ...state,
        currentStage: action.payload,
      };
      break;

    case DealsListActionTypes.SetCurrentCategory:
      state = {
        ...state,
        currentCategory: action.payload,
      };
      break;

    case DealsListActionTypes.SetLastSelectedListItemId:
      state = {
        ...state,
        lastSelectedListItemId: action.payload,
      };
      break;
    case DealsListActionTypes.ToggleIsAllowShowOtherDeals:
      state = {
        ...state,
        isAllowShowOtherDeals: !state.isAllowShowOtherDeals,
      };
      break;
    case DealsListActionTypes.PostSaleDealListItemSetMaximized:
      state = {
        ...state,
        listMaximizedTourPackages: state.listMaximizedTourPackages.slice(0).concat([action.payload]),
      };
      break;
    case DealsListActionTypes.PostSaleDealListItemSetMinimized:
      state = {
        ...state,
        listMaximizedTourPackages: state.listMaximizedTourPackages.slice(0).filter(v => v !== action.payload),
      };
      break;
    case DealsListActionTypes.PostSaleDealListItemSetMinimizedAll:
      state = {
        ...state,
        listMaximizedTourPackages: [],
      };
      break;
    case DealsListActionTypes.SetIsAllowShowOtherDeals:
      state = {
        ...state,
        isAllowShowOtherDeals: action.payload,
      };
      break;
    default:
      return state;
  }
  return state;
}
