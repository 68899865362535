import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, interval, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private offline$ = new BehaviorSubject<boolean>(!navigator.onLine);
  private slowConnection$ = new BehaviorSubject<boolean>(false);

  constructor() {
    const online$ = fromEvent(window, 'online').pipe(mapTo(false));
    const offline$ = fromEvent(window, 'offline').pipe(mapTo(true));

    merge(online$, offline$, of(!navigator.onLine)).subscribe(isOffline => {
      this.offline$.next(isOffline);
    });

    this.checkConnectionSpeed();
  }

  private checkConnectionSpeed(): void {
    interval(5000).subscribe(time => {
      const isSlow = (navigator as any).connection.effectiveType !== '4g';
      this.slowConnection$.next(isSlow);
    });
  }

  get isOffline$() {
    return this.offline$.asObservable();
  }

  get isSlowConnection$() {
    return this.slowConnection$.asObservable();
  }
}
