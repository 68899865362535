@if (isLoading | async) {
  <app-preloader />
}
<router-outlet />
<app-notify />

@if (mapLibrariesLoaded()) {
  <div class="search-result-container" [class.visible]="showSearchResultComponent$ | async">
    <app-search-result />
  </div>
}
