import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ManagerSearchHotelTemplate } from '@api-clients/api-client/models/manager-search-hotel-template';
import { ConfigsService } from '../../../../../../../../../../core/services';
import { ElectronService } from '../../../../../../../../../../core/services/electron.service';
import { SearchFormTemplatesDropdownItemComponent } from './search-form-templates-dropdown-item.component';

@Component({
  selector: 'app-search-form-templates-dropdown',
  templateUrl: './search-form-templates-dropdown.component.html',
  styleUrls: ['./search-form-templates-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchFormTemplatesDropdownItemComponent],
})
export class SearchFormTemplatesDropdownComponent {
  @Input() activeTemplate: ManagerSearchHotelTemplate;
  @Input() basicTemplates: ManagerSearchHotelTemplate[] = [];
  @Input() managerTemplates: ManagerSearchHotelTemplate[] = [];

  @Output() selected = new EventEmitter<ManagerSearchHotelTemplate>();

  constructor(
    private electron: ElectronService,
    private readonly config: ConfigsService,
  ) {}

  selectedTemplate(template: ManagerSearchHotelTemplate) {
    this.selected.emit(template);
  }

  openManagerSearch() {
    const searchURL = `${this.config.adminUrl}/search/manager`;
    this.electron.shell.openExternal(searchURL).then();
  }
}
