import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AiVoiceConvertedFile } from '@api-clients/api-client/models';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable({
  providedIn: 'root',
})
export class AiVoiceApiService {
  constructor(private http: HttpClient) {}

  convert(itemId: number, employeeId: number): Observable<AiVoiceConvertedFile> {
    return this.http
      .post<ApiResponse<AiVoiceConvertedFile>>(`${AppConfig.apiUrl}/ai-voice/convert`, {
        // Тип для аудио презентаций
        type: 1,
        itemId,
        employeeId,
      })
      .pipe(apiResponsePipe);
  }
}
