import { AsyncPipe } from '@angular/common';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import {
  ManagerSipStateEnum,
  ManagerSipStateInterface,
} from '../../../../+state/manager-state/manager-state.state';
import { ManagerStateFacade } from '../../../../+state/manager-state/manager-state.facade';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';

@Component({
  selector: 'app-worker-status',
  templateUrl: './worker-status.component.html',
  styleUrls: ['./worker-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatMenu, MatMenuItem, StatusIndicatorComponent, MatMenuTrigger, AsyncPipe],
})
export class WorkerStatusComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public currentManagerSipState$: Observable<ManagerSipStateInterface> =
    this.managerStateFacade.managerSipState$;
  public isOpen = false;
  public managerSipStateEnum = ManagerSipStateEnum;

  constructor(
    private managerStateFacade: ManagerStateFacade,
    private cd: ChangeDetectorRef,
  ) {}

  onManagerStatusChange(status: boolean): void {
    this.toggleDropdown();
    const managerState: ManagerSipStateInterface = {
      state: status ? ManagerSipStateEnum.online : ManagerSipStateEnum.manual_busy,
      value: status,
    };
    this.managerStateFacade.setManagerSipState(managerState);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.cd.markForCheck();
    if (this.isOpen) {
      this.trigger.openMenu();
    } else {
      this.trigger.closeMenu();
    }
  }
}
