<div class="countries-dropdown">
  <div class="dropdown-label-container">
    <div class="dropdown-label">Куда</div>
  </div>
  <div class="dropdown-items">
    @for (country of countries; track country) {
      <app-search-form-countries-dropdown-item
        [country]="country"
        [regions]="regions"
        [active]="country.id === currentActiveCountryId"
        [selectedRegionIds]="selectedRegionIds"
        (selectedDirection)="selectedCountry($event)"
        (closed)="closedDropdown()"
      />
    }
  </div>
</div>
