import { ChangeDetectionStrategy, Component, Input, OnInit, output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { NgClickOutsideDelayOutsideDirective, NgClickOutsideDirective } from 'ng-click-outside2';
import { CheckboxItem } from '../../checkbox-list/search-form-checkbox-list.component';

@Component({
  selector: 'app-search-form-country-dropdown',
  templateUrl: './search-form-country-regions-dropdown.component.html',
  styleUrls: ['./search-form-country-regions-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, NgClickOutsideDelayOutsideDirective],
})
export class SearchFormCountryRegionsDropdownComponent implements OnInit {
  @Input() regions: SearchFormParamsRegion[] = [];
  @Input() selectedRegionIds: number[] = [];

  selected = output<SearchFormParamsRegion[]>();
  closed = output<void>();
  closedCountry = output<void>();

  regionsCount = 0;
  items: CheckboxItem[] = [];

  readonly regionsLgSizeCount = 10;

  ngOnInit(): void {
    this.regionsCount = this.regions.length;
    this.items = this.regions.map(region => {
      const item: CheckboxItem = {
        id: region.id,
        name: region.name,
        selected: this.selectedRegionIds.indexOf(region.id) !== -1,
      };

      return item;
    });
  }

  selectRegion() {
    this.selected.emit(this.getSelected());
  }

  selectAllRegions() {
    // если не передаем id, то значит у нас все регионы для поиска
    this.selected.emit([]);
    this.closedCountry.emit();
  }

  outsideClick() {
    const selectedRegions = this.getSelected();
    if (selectedRegions.length) {
      this.selected.emit(selectedRegions);
    }
    this.closed.emit();
  }

  private getSelected(): SearchFormParamsRegion[] {
    return this.items
      .filter(item => item.selected)
      .map(item => this.regions.find(region => region.id === item.id));
  }
}
