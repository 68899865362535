import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { Observable, of, switchMap } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SearchFormService } from '../../../../search-form/search-form.service';

@Component({
  selector: 'app-search-result-map-search-on-place',
  templateUrl: './search-result-map-search-on-place.component.html',
  styleUrl: './search-result-map-search-on-place.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatAutocomplete, MatOption, MatAutocompleteTrigger],
})
export class SearchResultMapSearchOnPlaceComponent implements OnInit {
  map = input<GoogleMap>();

  placeNameControl = new FormControl('');
  placesVariants = signal<string[]>([]);
  countryCode = signal<string>('');

  private destroyRef = inject(DestroyRef);

  constructor(private readonly searchFormService: SearchFormService) {}

  ngOnInit(): void {
    this.searchFormService.selectedCountry$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v),
      )
      .subscribe(country => {
        this.countryCode.set(country.code);
      });

    this.placeNameControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(200),
        switchMap(value => {
          if (!!value && value.length > 2) {
            return this.fetchPlaces(value);
          }
          return of([]);
        }),
      )
      .subscribe(variants => {
        this.placesVariants.set(variants);
      });
  }

  findPlace(variant?: string) {
    const address = variant ? variant : this.placeNameControl.value;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        this.map().googleMap.setCenter(results[0].geometry.location);
        this.map().googleMap.setZoom(15);
      }
    });
  }

  onAutocompleteClosed() {
    if (!this.placeNameControl.value) {
      this.placesVariants.set([]);
    }
  }

  clearInput(): void {
    this.placeNameControl.setValue('');
    this.placesVariants.set([]);
  }

  private fetchPlaces(query: string): Observable<string[]> {
    return new Observable(observer => {
      const autocomplete = new google.maps.places.AutocompleteService();
      const request2: google.maps.places.AutocompletionRequest = {
        input: query,
        language: 'ru',
        componentRestrictions: {
          country: this.countryCode(),
        },
      };
      autocomplete.getPlacePredictions(request2, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            observer.next(results.map(result => result.structured_formatting.main_text));
          } else {
            observer.next([]);
          }
          observer.complete();
        }
      });
    });
  }
}
