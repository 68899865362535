import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchHotelStarsComponent } from '../../../../../../../../../../../search/components/hotel/hotel-stars/search-hotel-stars.component';
import { SearchResultFiltersService } from '../../../../services/search-result-filters.service';

export interface CheckboxItem {
  id: string;
  selected: boolean;
}

@Component({
  selector: 'app-favorite-hotels-map-filter-stars',
  templateUrl: './favorite-hotels-map-filter-stars.component.html',
  styleUrl: './favorite-hotels-map-filter-stars.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchHotelStarsComponent, SearchHotelStarsComponent, FormsModule],
})
export class FavoritesHotelsMapFilterStarsComponent implements OnInit {
  @Input() starsList: string[] = [];
  @Input() selectedIds: string[] = [];

  items: CheckboxItem[] = [];

  constructor(private readonly mapService: SearchResultFiltersService) {}

  ngOnInit() {
    this.items = this.starsList
      .map(stars => {
        const selected = this.selectedIds.includes(stars);

        return {
          id: stars,
          selected,
        };
      })
      .sort((a, b) => {
        const isNumberA = !isNaN(Number(a.id));
        const isNumberB = !isNaN(Number(b.id));

        if (isNumberA && isNumberB) {
          return Number(b.id) - Number(a.id);
        }

        if (isNumberA) {
          return -1;
        }

        if (isNumberB) {
          return 1;
        }

        return a.id.localeCompare(b.id);
      });
  }

  onCheckboxChange() {
    const selectedItems = this.items.filter(item => item.selected).map(item => item.id);

    this.mapService.applyHotelStarsFilter(selectedItems);
  }
}
